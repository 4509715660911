import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";
import { LoanProductService } from 'app/product/service/loan-product.service';

@Component({
  selector: 'app-loan-outstanding',
  templateUrl: './loan-outstanding.component.html',
  styleUrls: ['./loan-outstanding.component.scss']
})
export class LoanOutstandingComponent implements OnInit {
  
  asOnDate:Date = new Date();
  mfsType:number=0;
  leadNo:string=null;
  mfsAccountList:any[];
  outstandingLoanList:any[];
  constructor(private pdfService:PdfService,
    private location: Location,
    private productService:LoanProductService,
    private changeDetectorRef:ChangeDetectorRef,
    private datePipe:DatePipe) { }

  ngOnInit(): void {
    this.getMFSList();
  }
  OpenPDF(){
    this.pdfService.LoanOutstandingReport(this.leadNo,this.asOnDate,this.mfsType).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
  OpenExcel(){
    this.pdfService.LoanOutstandingExcelReport(this.leadNo,this.asOnDate,this.mfsType).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
  goBack() {
    this.location.back();
  }

  getMFSList() {
    this.productService.getMFSAccountList().subscribe((res) => {
      this.mfsAccountList = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  LoadData(){
    this.pdfService.LoanOutstandingData(this.leadNo,this.asOnDate,this.mfsType).subscribe(res=>{
      this.outstandingLoanList=res.body;
    })
  }

  formatDateString(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, 'dd-MM-yyyy'); // Adjust the format as needed
    return formattedDate || dateString;
  }
}
