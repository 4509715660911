<!--Statistics cards Starts-->
<div class="row">
    <div class="col-xl-3 col-lg-6 col-12" (click)="onDisburse(5)" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body text-left">
                            <h3 class="mb-1 Secondary">{{waitingForDisburse}}</h3>
                            <span style="color:black;font-size: 14px;">Waiting loan</span>
                        </div>
                        <div class="media-right align-self-center">
                            <!-- <i class="ft-circle info font-large-2 float-right"></i> -->
                            <i class="ft-rotate-cw Secondary font-large-2 float-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="FinalDisburse(7)" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body text-left">
                            <h3 class="mb-1 Secondary">{{finalDisbursList}}</h3>
                            <span style="color:black;font-size: 14px;">Disbursed loan</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-check-circle  Secondary font-large-2 float-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="onRejected(4)" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body text-left">
                            <h3 class="mb-1 Secondary">{{rejectedLoan}}</h3>
                            <span style="color:black;font-size: 14px;">Rejected Loan</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-x-circle Secondary font-large-2 float-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="ClProcess()" style="cursor: pointer;">
      

        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body text-left">
                            <h3 class="mb-1 Secondary">{{totalRunningLoan}}</h3>
                            <span style="color:black;font-size: 14px;">CL Classification</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-slack Secondary font-large-2 float-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
</div>

<div class="row">
    <div class="col-xl-3 col-lg-6 col-12" (click)="TodayCollection()" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body text-left">
                            <h3 class="mb-1 Secondary">{{todayReceivableAmount}}</h3>
                            <span style="color:black;font-size: 14px;">Today Receivable</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-layers Secondary font-large-2 float-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="onReceivable()" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body text-left">
                            <h3 class="mb-1 Secondary">{{ totalReceivable?.toString() || '0' }}</h3>
                            <span style="color:black;font-size: 14px;">Total Receivable</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-layers Secondary font-large-2 float-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="TotalOverdue()" style="cursor: pointer;">
        <div class="TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body text-left">
                            <h3 class="mb-1 Secondary">{{todayOverdue}}</h3>
                            <span style="color:black;font-size: 14px;">Total Overdue</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-credit-card Secondary font-large-2 float-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12" (click)="TotalPayable()" style="cursor: pointer;">
        <div class=" TopCard" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="card-content">
                <div class="card-body">
                    <div class="media">
                        <div class="media-body text-left">
                            <h3 class="mb-1 Secondary">{{totalPayable}}</h3>
                            <span style="color:black;font-size: 14px;">Total Payable</span>
                        </div>
                        <div class="media-right align-self-center">
                            <i class="ft-credit-card Secondary font-large-2 float-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</div>


<div class="row">
    <div class="col-sm-4 col-md-4">
       
        <div class="" style="background-color: #FFFFFF;margin: 15px 0;" >

              <div class="card-body" >
                <h4 class="mt-2 text-center">Account Info</h4>
                <ul class="list-group" style="color:black">
                  <li class="list-group-item" [routerLink]="'/customer/list'">
                  
                    <span class="badge bg-success float-right" style="width: 80px!important;">{{registerCustomer}}</span>Register Customer
                  </li>
                  <li class="list-group-item" >
                    <span class="badge bg-success float-right" style="width: 80px!important;">{{savingAccount}}</span>Saving Account 
                  </li>
                  <li class="list-group-item"  [routerLink]="'/fdr-select/fdr-list'">
                    <span class="badge bg-success float-right" style="width: 80px!important;">{{FdrAccount}}</span>FDR Account 
                  </li>
                  <li class="list-group-item" [routerLink]="'/dps-account/dps-list'">
                    <span class="badge bg-success float-right" style="width: 80px!important;">{{dpsAccount}}</span>DPS Account
                  </li>
                  <li class="list-group-item"  [routerLink]="'/loan-application/loan-list'"> 
                    <span  class="badge bg-success float-right" style="width: 80px!important;">{{LoanApplication}}</span>Loan Account
                  </li>
                </ul>
              </div>
          
            
        </div>

        <div class="" style="background-color: #FFFFFF!important;margin: 15px 0;">
            
                <h4 class=""><span style="margin-top:10%">Loan Payment Status</span></h4>
           
            <div class="">

                <div id="donut-dashboard-chart" class="donut">
                    <x-chartist class="height-250" [data]="DonutChart.data" [type]="DonutChart.type"
                        [options]="DonutChart.options" [responsiveOptions]="DonutChart.responsiveOptions"
                        [events]="DonutChart.events">
                    </x-chartist>
                </div>

                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col">
                            <span class="mb-1 text-muted d-block" style="color: black !important;"> {{allLoanStatus[0]?.regularLoan}}% - Regular</span>
                            <div class="progress" style="height: 5px;">
                                <!-- <div class="progress-bar bg-info" role="progressbar" style="width: {{allLoanStatus[0].regularLoan}}%;"
                                    aria-valuenow="23" aria-valuemin="0" aria-valuemax="100"></div> -->
                            </div>
                        </div>
                        <div class="col">
                            <span class="mb-1 text-muted d-block" style="color: black !important;">{{allLoanStatus[0]?.advanceLoan}}% - Advance</span>
                            <div class="progress" style="height: 5px;">
                                <!-- <div class="progress-bar bg-success" role="progressbar" style="width: {{allLoanStatus[0].advanceLoan}}%;"
                                    aria-valuenow="28" aria-valuemin="0" aria-valuemax="100"></div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <span class="mb-1 text-muted d-block" style="color: black !important;">{{allLoanStatus[0]?.osLoan}}% - OS</span>
                            <div class="progress" style="height: 5px;">
                                <!-- <div class="progress-bar bg-primary" role="progressbar" style="width: {{allLoanStatus[0].osLoan}}%;"
                                    aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div> -->
                            </div>
                        </div>
                        <div class="col">
                            <span class="mb-1 text-muted d-block" style="color: black !important;">{{allLoanStatus[0]?.odLoan}}% - Overdue</span>
                            <div class="progress" style="height: 5px;">
                                <!-- <div class="progress-bar bg-warning" role="progressbar" style="width: {{allLoanStatus[0].odLoan}}%;"
                                    aria-valuenow="14" aria-valuemin="0" aria-valuemax="100"></div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    


    <div class="col-xl-8 col-12 " >

        <!-- <div class="">
            <h4 class="mt-2">All Information</h4>
        </div> -->

        <!-- Doughnut Chart Starts -->

        <div class="col-xl-12 col-lg-12 col-12" style="background-color: #FFFFFF;margin: 15px 0;">
            <div class="" >
                <div class="">
                    <h4 class=" mb-0">Loan & Deposit Statistics</h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <div class="chart-info mb-2">
                            <span class="text-uppercase mr-3"><i class="fa fa-circle success font-small-2 mr-1"></i>
                                Loan</span>
                            <span class="text-uppercase"><i class="fa fa-circle info font-small-2 mr-1"></i>
                                Deposit</span>
                        </div>
                        <div id="line-area2" class="lineArea2">
                            <x-chartist class="height-400" [data]="lineArea2.data" [type]="lineArea2.type"
                                [options]="lineArea2.options" [responsiveOptions]="lineArea2.responsiveOptions"
                                [events]="lineArea2.events">
                            </x-chartist>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row text-left">
            <div class="col-sm-12">
                <div class="" style="background-color: #FFFFFF;margin: 15px 0;">
                    <div class="mt-2">
                        <h4 class="">Total Amount Receivable</h4>
                    </div>
                    <div class="card-content">
                        <div class="card-body">
                            <div id="bar-doughnut" class="height-300">
                                <canvas baseChart class="chart" [data]="doughnutChartData"
                                    [labels]="doughnutChartLabels" [options]="doughnutChartOptions"
                                    [chartType]="doughnutChartType" [colors]="doughnutChartColors"
                                    chartHover="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>




<!-- Bar Chart Starts -->
<div class="row text-left">
    <div class="col-sm-12" style="background-color: #FFFFFF;margin: 15px 0;">
        <div class="">
            <div class="">
                <h4 class="card-title">Monthly Loan & DPS Collection Information</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div id="bar-bar1" class="height-400">
                        <canvas baseChart class="chart" [datasets]="barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend"
                            [chartType]="barChartType" chartHover="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<!-- Bar Chart Ends -->







<div class="row match-height">
    <div class="col-xl-4 col-lg-12 col-12 d-none">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Statistics</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <p class="font-medium-2 text-center my-2">Loan Information</p>
                    <!-- <div id="Stack-bar-chart" class="Stackbarchart mb-2">
                        <x-chartist class="height-300" [data]="Stackbarchart.data" [type]="Stackbarchart.type"
                            [options]="Stackbarchart.options" [responsiveOptions]="Stackbarchart.responsiveOptions"
                            [events]="Stackbarchart.events">
                        </x-chartist>
                    </div>  -->
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-xl-12 col-lg-12 col-12">
        <div class="card shopping-cart">
            <div class="card-header pb-2">
                <h4 class="card-title mb-1">Account Details</h4>
            </div>
            <div class="card-content">
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table text-center m-0 table-borderless">
                            <thead>
                                <tr>
                                    <th>Account Type</th>
                                    <th>Account Name</th>
                                    <th>Account No</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of customerList">
                                    <td>{{item.accountType}}</td>
                                    <td>{{item.accountTilte}}</td>
                                    <td>{{item.customerAccountNo}}</td>
                                    <td>{{item.openingBalance}}</td>
                                    <td>
                                        <button class="btn" (click)="PrintKyc()"
                                            title="Click here for download pdf">View PDF</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<!--Line with Area Chart 1 Starts-->
<div class="row">
    <!-- <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Loan Details</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="chart-info mb-3 ml-3">
                        <span class="gradient-purple-bliss d-inline-block rounded-circle mr-1"
                            style="width:15px; height:15px;"></span>
                        <span></span>
                        <span class="gradient-mint d-inline-block rounded-circle mr-1 ml-2"
                            style="width:15px; height:15px;"></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="col-xl-12 col-lg-12 col-12">
        <div class="card shopping-cart">
            <div class="card-header pb-2">
                <h4 class="card-title mb-1">Loan Details</h4>
            </div>
            <div class="card-content">
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table text-center m-0 table-borderless">
                            <thead>
                                <tr>
                                    <th>Account Type</th>
                                    <th>Account Name</th>
                                    <th>Account No</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of customerLoan">
                                    <td>{{item.accountType}}</td>
                                    <td>{{item.accountTilte}}</td>
                                    <td>{{item.customerAccountNo}}</td>
                                    <td>{{item.openingBalance}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="row match-height">
    <div class="col-md-4 col-12">
        <div class="card">
            <div class="card-header text-center pb-0">
                <span class="font-medium-2 warning">Received</span>
                <h3 class="font-large-2 mt-2">70261</h3>
            </div>
            <div class="card-content">
                <div id="donut-chart1" class="donut1">
                    <x-chartist class="height-250" [data]="DonutChart1.data" [type]="DonutChart1.type"
                        [options]="DonutChart1.options" [responsiveOptions]="DonutChart1.responsiveOptions"
                        [events]="DonutChart1.events">
                    </x-chartist>
                </div>
                <div class="card-body text-center">
                    <span class="font-large-1 d-block mb-1">100000</span>
                    <span class="font-medium-1">Today's Receivable</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-12">
        <div class="card">
            <div class="card-header text-center pb-0">
                <span class="font-medium-2 info">Received</span>
                <h3 class="font-large-2 mt-2">11500
                </h3>
            </div>
            <div class="card-content">
                <div id="donut-chart2" class="donut2">
                    <x-chartist class="height-250" [data]="DonutChart2.data" [type]="DonutChart2.type"
                        [options]="DonutChart2.options" [responsiveOptions]="DonutChart2.responsiveOptions"
                        [events]="DonutChart2.events">
                    </x-chartist>
                </div>
                <div class="card-body text-center">
                    <span class="font-large-1 d-block mb-1">40000</span>
                    <span class="font-medium-1">Today's Payable</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-12">
        <div class="card">
            <div class="card-header text-center pb-0">
                <span class="font-medium-2 danger">Received</span>
                <h3 class="font-large-2 mt-2">4025
                </h3>
            </div>
            <div class="card-content">
                <div id="donut-chart3" class="donut3">
                    <x-chartist class="height-250" [data]="DonutChart3.data" [type]="DonutChart3.type"
                        [options]="DonutChart3.options" [responsiveOptions]="DonutChart3.responsiveOptions"
                        [events]="DonutChart3.events">
                    </x-chartist>
                </div>
                <div class="card-body text-center">
                    <span class="font-large-1 d-block mb-1">15000</span>
                    <span class="font-medium-1">Total Overdue</span>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- 
<div class="row text-left">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Line Chart</h4>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div id="line-chart3" class="">
                        <x-chartist class="height-400" [data]="lineChart3.data" [type]="lineChart3.type" [options]="lineChart3.options" [responsiveOptions]="lineChart3.responsiveOptions"
                            [events]="lineChart3.events">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

