<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h5 class="card-title" style="color: black">DPS Encashment List</h5>
                </div>
                <!-- <div class="col" style="text-align: right;">
                    <button class="btn btn-primary" (click)="openingCreate()">Create</button>
                </div> -->
            </div>
            <table id="example" class="table table-striped table-bordered mt-3" style="width:100%">
                <thead style="background-color: #f8f9fb;">
                    <tr>
                        <th>Action</th>
                        <th>Account No</th>
                        <th>Account Name</th>
                        <th>Encashment Amount</th>
                        <th>Encashment Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container ></ng-container>
                    <tr *ngFor="let item of dpsEncashmentList">
                        <td>
                            <!-- <button class="btn btn-primary" style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="PrintKyc(item.customerAccount?.id)" title="Click here for download pdf">View PDF</button> -->
                            <button class="btn btn-primary mt-1"
                                style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="DPSSchedulePDF(item.customerAccount?.id)"
                                title="Click here for download pdf">Schedule</button>
                            <button class="btn btn-primary mt-1" *ngIf="item.isApproved == 0"
                                style="font-size: 14px; width: 120px !important; height: 35px;"
                                (click)="Approve(item.id)"
                                title="Click here for download pdf">Approve</button>
                        </td>
                        <td>{{item.customerAccount?.customerAccountNo}}</td>
                        <td>{{item.customerAccount?.customerAccountNo}}</td>
                        <td>{{item.encashmentAmount}}</td>
                        <td>{{item.effectiveDate | date: 'yyyy-MM-dd'}}</td>
                        <td>{{item.isApproved == 0 ? 'Pending' : 'Approved'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>