import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountingReportComponent } from './accounting-report/accounting-report.component';
import { TrialBalanceReportComponent } from './trial-balance-report/trial-balance-report.component';
import { GroupSummaryComponent } from './group-summary/group-summary.component';
import { CcwiseReportComponent } from './ccwise-report/ccwise-report.component';
import { LedgerBookReportComponent } from './ledger-book-report/ledger-book-report.component';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { ProfitLossReportComponent } from './profit-loss-report/profit-loss-report.component';
import { BalannceSheetReportComponent } from './balannce-sheet-report/balannce-sheet-report.component';
import { CashBookComponent } from './cash-book/cash-book.component';
import { BankBookComponent } from './bank-book/bank-book.component';
import { StatementReportComponent } from './statement-report/statement-report.component';
import { ChartOfAccountComponent } from './chart-of-account/chart-of-account.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { AccountStatmentReportComponent } from './account-statment-report/account-statment-report.component';
import { DayBookComponent } from './day-book/day-book.component';
import { SubLedgerBookComponent } from './sub-ledger-book/sub-ledger-book.component';
import { LoanOutstandingComponent } from './loan-outstanding/loan-outstanding.component';
import { LoanOverdueComponent } from './loan-overdue/loan-overdue.component';
import { IncomeExpenseReportComponent } from './income-expense-report/income-expense-report.component';
import { LoanDisbursementComponent } from './loan-disbursement/loan-disbursement.component';
import { MisReportComponent } from './mis-report/mis-report.component';
import { LoanCollectionComponent } from './loan-collection/loan-collection.component';
import { LoanSettlementComponent } from 'app/dashboard/loan-settlement/loan-settlement.component';
import { LoanInterestIncomeComponent } from './loan-interest-income/loan-interest-income.component';
import { LoanSettlementReportComponent } from './loan-settlement-report/loan-settlement-report.component';

const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'accounting-report',
        component: AccountingReportComponent,
        data: {
          title: 'Account Report'
        },
        canActivate:[AuthGuard]
      },
     
      {
        path: 'trial-balance-report',
        component: TrialBalanceReportComponent,
        data: {
          title: 'trial-balance-report'
        },
        canActivate:[AuthGuard]
      },
     
      {
        path: 'group-summary',
        component: GroupSummaryComponent,
        data: {
          title: 'group-summary'
        },
        canActivate:[AuthGuard]
      },
     
      {
        path: 'ccwise-report',
        component: CcwiseReportComponent,
        data: {
          title: 'ccwise-report'
        },
        canActivate:[AuthGuard]
      },
     
      {
        path: 'ledger-book-report',
        component: LedgerBookReportComponent,
        data: {
          title: 'Account Report'
        },
        canActivate:[AuthGuard]
      },

      {
        path: 'accounting-report-builder',
        component: ReportBuilderComponent,
        data: {
          title: 'Account Report Builder'
        },
        canActivate:[AuthGuard]
      },
     
      {
        path: 'profit-loss-report',
        component: ProfitLossReportComponent,
        data: {
          title: 'profit-loss-report'
        },
        canActivate:[AuthGuard]
      },

        {
        path: 'income-expense-report',
        component: IncomeExpenseReportComponent,
        data: {
          title: 'income-expense-report'
        },
        canActivate:[AuthGuard]
      },
     
      {
        path: 'balannce-sheet-report',
        component: BalannceSheetReportComponent,
        data: {
          title: 'balannce-sheet-report'
        },
        canActivate:[AuthGuard]
      },

      {
        path: 'cash-book',
        component: CashBookComponent,
        data: {
          title: 'cash-book'
        },
        canActivate:[AuthGuard]
      },

 
      {
        path: 'bank-book',
        component: BankBookComponent,
        data: {
          title: 'bank-book'
        },
        canActivate:[AuthGuard]
      },
     
      {
        path: 'statement-report',
        component: StatementReportComponent,
        data: {
          title: 'statement-report'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'account-statment-report',
        component: AccountStatmentReportComponent,
        data: {
          title: 'account-statment-report'
        },
        canActivate:[AuthGuard]
      },
     
    
      {
        path: 'chart-of-account',
        component: ChartOfAccountComponent,
        data: {
          title: 'chart-of-account '
        },
        canActivate:[AuthGuard]
      },

      {
        path: 'day-book',
        component: DayBookComponent,
        data: {
          title: 'day-book'
        },
        canActivate:[AuthGuard]
      },

      {
        path: 'sub-ledger-book',
        component: SubLedgerBookComponent,
        data: {
          title: 'sub-ledger-book'
        },
        canActivate:[AuthGuard]
      },
     
     
      {
        path: 'loan-outstanding',
        component: LoanOutstandingComponent,
        data: {
          title: 'loan-outstanding'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'loan-overdue',
        component: LoanOverdueComponent,
        data: {
          title: 'loan-overdue'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'loan-disbursement',
        component: LoanDisbursementComponent,
        data: {
          title: 'loan-disbursement'
        },
        canActivate:[AuthGuard]
      },
      {
        path: 'mis-report',
        component: MisReportComponent,
        data: {
          title: 'mis-report'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'loan-collection',
        component:LoanCollectionComponent,
        data:{
          title:'loan-collection'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'loan-settlement-report',
        component:LoanSettlementReportComponent,
        data:{
          title:'loan-settlement-report'
        },
        canActivate:[AuthGuard]
      },
      {
        path:'loan-interest-income',
        component:LoanInterestIncomeComponent,
        data:{
          title:'loan-interest-income'
        },
        canActivate:[AuthGuard]
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountingReportsRoutingModule { }
