import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Bank } from 'app/Score/model/bank';
import { CustomerService } from 'app/customer/customer.service';
import { SharedService } from 'app/services/shared.service';
import { Observable, of } from 'rxjs';
import { Bkash } from '../model/bkash';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-b-kash',
  templateUrl: './b-kash.component.html',
  styleUrls: ['./b-kash.component.scss']
})
export class BKashComponent implements OnInit {

  frmGroup : FormGroup;
  formControl = new FormControl();
  AccountNoList : Observable<any[]>;
  customerAccountId : number;
  customerAccountId2 : string;
  customerAccountIds : string;
  currentBalance: number;
  accountId : number;
  customerInfo_cashFundTransfer1: any = '';
  isBtnValid: boolean = false;
  glAccountName:any;
  accountNotFound1 : string  = '';
 
  accountNoFund2: string = '';
  isvalid: boolean = true;
  //model = new Bkash();
  model : any= {};
  isEdit: boolean = false;
  mobileBankingList: Observable<any[]>;
  accountType: string = '';
  id:number;
  accountName: string = '';
  selectedSuggetion: any = null;
  hideShow: string = 'Show';
  searchType: string = '';
  suggestions: any[] = [];
  totalCurrentBalance: number;



  constructor(
    private _CustomerService: CustomerService,
    private shared: SharedService,
    private router: Router,
    private formBuilder: FormBuilder,
    
  ) { 
    this.frmGroup = new FormGroup({
      id: new FormControl(),
      customerAccountId: new FormControl(),
      customerAccountId2: new FormControl(), 
        customerCode: new FormControl(),
        transactionType: new FormControl(),
        customerId: new FormControl(),
        voucherNo: new FormControl(),
        postingType: new FormControl(),
        remarks: new FormControl(),
        currencyId: new FormControl(),
        customersId: new FormControl(),
        voucherAmount: new FormControl(),
        gLGenerateId:new FormControl(),
     

    })
    this.frmGroup = this.formBuilder.group({ 
       
      voucherAmount: ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]] ,
      customerCode: ['', Validators.required],
      customerAccountId: [0, Validators.required],
      voucherNo: ['', Validators.required],
      remarks: ['', Validators.required],
      currencyId: [0, Validators.required],
      customerAccountId2: ['', Validators.required],

    }); 
    
  }

  ngOnInit(): void {
    this.GetCustomerAccountNo();
    this.GetMobileBankingAccountInfo();

  }

 
  GetCustomerAccountNo() {
    this._CustomerService.GetCustomerAccountNo().subscribe(res => {
      this.AccountNoList = of(res);
    });
  }
  loadUserDataByAccountNoFound1(accountId:number){
    this._CustomerService.GetCustomerInfoByAccountNoById(accountId).subscribe(res=>{
      if(res.glAccountName == null){
        this.currentBalance = res.currentBalance;
        this.totalCurrentBalance = res.currentBalance.toLocaleString();
        this.customerInfo_cashFundTransfer1 = res.customerNameEng + ', ' + res.mobileNo + ', ' + res.nidNo;
        this.glAccountName = '';
        this.customerAccountId = res.id;
        this.isBtnValid = true;
      }
      else{
        this.glAccountName = res.glAccountName;
        this.customerInfo_cashFundTransfer1 = '';
        this.isBtnValid = true;
      }
    })
  }



//
  GetMobileBankingAccountInfo(){
    var accountType='bkash';
    this._CustomerService.GetMobileBanking(accountType).subscribe(res=>{
      this.mobileBankingList = of(res);
    })
  }


  // loadMobileBankingInfoById(id:number){
  //   var accountType='bkash';
  //   this._CustomerService.GetMobileBankingById(id, accountType).subscribe(res=>{
  //     if(res != null){
  //       this.accountName = res.accountName;
  //     this.customerAccountId2 = res.bankInfoId;
  //     //this.isBtnValid = true;
  //     }
  //     else{
  //       this.shared.ShowAlert("Warning", "Invalid Number", "warning");
  //       this.accountName = '';
  //     }
  //   })
  // }

  decreaseAmountValue(value : any){
    var balanceAmount = this.totalCurrentBalance.toString().replace(/,/g, '');
    var inputAmount = parseInt(value.replace(/,/g, ''), 10);

    if(inputAmount > 0){
      this.currentBalance = parseInt(balanceAmount) - inputAmount;
    }
    else{
      this.currentBalance = this.totalCurrentBalance;
    }
  }

  onsubmit(){

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send this Amount!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!"
    }).then((res) => {
      if (res.isConfirmed) {
   
    if(this.isEdit){
      
      this.model.id = this.frmGroup.value.id;
    }
    else{
      this.model.id = 0;
    }
    this.model.transactionType = 3;
    this.model.customerAccountId = this.frmGroup.value.customerAccountId;
    this.model.customerAccountId2 = this.frmGroup.value.customerAccountId2;
    this.model.transactionTypeId = 2;
    this.model.ledgerRelationId = 110;
     this.model.remarks = this.frmGroup.value.remarks;
    this.model.voucherAmount = this.frmGroup.value.voucherAmount.replace(/,/g, '');
    if(this.model.voucherAmount > 0){
        this._CustomerService.SaveMobileBanking(this.model).subscribe((res:number)=>{
          if(res > 0 ){
            
            this.ResetForm();
            this.shared.ShowAlert("Success", "Save Successfully", "success");
            this.isEdit = false;
          }
          else{
            this.shared.ShowAlert("Warning", "Failed to Save", "warning");
            this.isEdit = false;
          }
        })
      
    }
  }
  else if (res.isDismissed) {
    this.router.navigate(['/accounting/b-kash']);
  }
});

} 


  formatNumber(value: string): string {

    const formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedValue;
  }

  CheckBalance(){
    var balanceAmount = this.currentBalance.toString().replace(/,/g, '');
    var inputAmount = parseInt(this.frmGroup.value.voucherAmount.replace(/,/g, ''), 10);

    if (inputAmount > parseInt(balanceAmount, 10))
    {
      this.isvalid = false;
      this.isBtnValid = false;
    }
     else
    {
      this.isvalid = true;
      this.isBtnValid = true;
    }
  }

  ResetForm() {
    this.frmGroup.reset();
    this.customerInfo_cashFundTransfer1 = '';
    this.currentBalance = 0;
    this.accountName  = '';
    this.isBtnValid = false;
  }
}
