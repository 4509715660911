import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.baseUrl + '/api/Dashboard/';
const apiUrl2 = environment.baseUrl + '/api/CustomerAccount/';
const apiLoan = environment.baseUrl + '/api/LoanDisbursement/';

@Injectable({
  providedIn: "root",
})
export class DashboardCustomService {
  constructor(private http: HttpClient) {}

  getCountLoanInfo(): Observable<any> {
    return this.http.get(apiUrl + "GetTotalLoanInfoForClientDash");
  }

  // getCustomerAccountByCustomerId(): Observable<any>{
  //   return this.http.get(apiUrl2+ 'GetAllCustomerAccountByCustomerId');
  // }

  getCustomerAccountByCustomerId(): Observable<any> {
    return this.http.get(apiUrl2 + "GetustomerAccountBalanceByCustomerId");
  }

  getCustomerAccountLoan(): Observable<any> {
    return this.http.get(apiUrl2 + "GetCustomerAccountLoan");
  }

  getCustomerLoanAccountInfo(): Observable<any> {
    return this.http.get(apiUrl2 + "GetCustomerAccountLoanForWeb");
  }

  getLoanCollection(): Observable<any> {
    return this.http.get(apiLoan + "GetLoanCollectionByCustomerId");
  }

  getAllRole(): Observable<any> {
    return this.http.get(apiUrl + "GetAllRoleList");
  }

  getUserRole(): Observable<any> {
    return this.http.get(apiUrl + "GetAllUserRoleByUser");
  }

  getLoanLeadInfoForAdmin(): Observable<any> {
    return this.http.get(apiUrl + "GetLeadGenerationListForAdminDash");
  }

  getAppliedLoanLeadInfoForDisburse(): Observable<any> {
    return this.http.get(apiUrl + "GetAppliedLeadListForAdminDash");
  }
  getAppliedLoanLeadInfoForDisburseBYDate(
    startDate: any,
    EndDate: any
  ): Observable<any> {
    return this.http.get(
      apiUrl +
        "GetAppliedLeadListForAdminDashByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  GetLoanInfoForAdminDeshBoard(): Observable<any> {
    return this.http.get(apiUrl + "GetLoanInfoForAdminDeshBoard");
  }

  GetAccountCountAdminDeshBoard(): Observable<any> {
    return this.http.get(apiUrl + "GetCustomerAccountForAdminDeshBoard");
  }
  getDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }
  getApprovedDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }
  getFinalDisbursLoan(status: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanApplication/GetLeadGenerationByStatus?status=" +
        status
    );
  }

  getFinalDisbursLoanByDate(startDate: any, EndDate: any): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanApplication/GetFinalDisbursListLoanByDate?startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  GetTodayCollectionList(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/Dashboard/GetTodayCollectionList"
    );
  }

  disbursLoan(id: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanDisbursement/DisbursLoan/" + id
    );
  }

  disbursLoanByLeadCode(leadCode: number): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/DisbursLoanByLeadCode/" +
        leadCode
    );
  }

  /////
  RejectLoan(id: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanDisbursement/RejectLoanByLeadId/" + id
    );
  }

  FinalDisbursLoan(id: number): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanDisbursement/FinalDisbursLoan/" + id
    );
  }

  getListForCustomerDashboard(type: number): Observable<any> {
    return this.http.get(apiUrl + "CustomerDashboardList/" + type);
  }
  getLoanCollectionByLeadId(type: number): Observable<any> {
    return this.http.get(apiUrl + "GetLoanCollectionByLeadId/" + type);
  }
  getCustomerAccount(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/LoanDisbursement/GetCustomerAccountByLeadId"
    );
  }

  GetOutStadingByLeadId(leadId: any) {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/GetOutStadingByLeadId/" +
        leadId
    );
  }

  GetSetteletementOutStandingByLeadId(leadId: any) {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/GetLoanSettleOutStadingByLeadId/" +
        leadId
    );
  }

  GetOutStadingCollectionByLeadId(leadId: any) {
    return this.http.get(
      environment.baseUrl +
        "/api/LoanDisbursement/GetOutStadingCollectionByLeadId/" +
        leadId
    );
  }

  getClProcessListByClTypeWise(): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/COBProcess/GetCobLogHistoryByCLtype"
    );
  }

  getAllLoanStatus(): Observable<any> {
    return this.http.get(apiUrl + "GetAllLoanStatus");
  }

  getLoanListForTS(): Observable<any> {
    return this.http.get(apiUrl + "CustomerLoanList");
  }

  GetAllDPSEncashment(): Observable<any> {
    return this.http.get(apiUrl2 + "GetAllDPSEncashment");
  }

  ApproveDPSEncashment(id: number): Observable<any> {
    return this.http.get(apiUrl2 + "ApproveDPSEncashment?id=" + id);
  }



  ApproveLeadByUnderwriting(
    leadId: number
  ): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/ApproveLeadByUnderwriting?leadId=" +
        leadId
    );
  }
}
