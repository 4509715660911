
<br>
<h4 class="card-title" style="color: black">Process Account Balance</h4>
<hr>
<div class="card">
    <div class="card-body">
        <div class="form-group row">
           <!-- <label for="type" class="col-md-1">Type:</label>
           <div class="col-2">
               <select type="text" class="form-control"  [(ngModel)]="types">
                    <option value="">Select</option>   
                    <option value="Deposit">Deposit</option>
                    <option value="FDR">FDR</option>
                    <option value="DPS">DPS</option>
                    <option value="Loan">Loan</option>
               </select>
           </div> -->

            <label for="dateInput" class="col-md-2">From Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [(ngModel)]="fromDate" class="form-control" > 
            </div>

            <label for="dateInput" class="col-md-2">To Date:</label>
            <div class="col-md-2 input-group">
                <input type="date" [(ngModel)]="toDate" class="form-control">
            </div>

            <div class="col-2">
                <!-- <button type="button" class="btn btn-primary" id="processButton">Process</button> -->
            </div>
        </div>
        <hr>
        <div class="row">
            <table style="width: 100%;">
                <thead>
                    <tr style="background-color: rgb(180, 179, 179);">
                        <th>
                            <input (click)="onChangeCheckbox('All', 'All')" type="checkbox" id="allCheck" [(ngModel)]="isAllProcessed" class="form-control checkbox">
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;">Check All</label>
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;"></label>
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;"></label>
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;"></label>
                        </th>
                        <th>
                            <label for="allCheck" style="font-weight: bold; margin: 0;"></label>
                        </th>
                        <th>
                            <div style="display: flex;">
                                <!-- <a href="javascript.void(0)" (click)="ProcessAll()" class="btn btn-outline-info btn-sm">Process</a> -->
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody style="background-color: white;">
                    <tr *ngFor="let item of batchDetails">
                        <td style="width: 5%;">
                            <input id="BalanceFDR" (click)="onChangeCheckbox('Balance', 'FDR')" type="checkbox" [(ngModel)]="isBalanceFDRChecked" class="form-control checkbox">
                            <input type="hidden" name="name" value="{{item.batchDetail?.name}}">
                            <input type="hidden" name="id" value="{{item.batchDetail?.id}}">
                        </td>
                        <td style="">
                            <label for="BalanceFDR" style="font-weight: bold; margin: 0;">{{item.batchDetail.name}}</label>
                        </td>
                        <td style="">
                            <input type="text" name="{{item.parameterInfos[0]?.name}}" placeholder="@{{item.parameterInfos[0]?.name}}" class="form-control">
                        </td>
                        <td style="">
                            <input type="text" name="{{item.parameterInfos[1]?.name}}" placeholder="@{{item.parameterInfos[1]?.name}}" class="form-control">
                        </td>
                        <td style="">
                            <input type="text" name="{{item.parameterInfos[2]?.name}}" placeholder="@{{item.parameterInfos[2]?.name}}" class="form-control">
                        </td>
                        <td style="">
                            <input type="text" name="{{item.parameterInfos[3]?.name}}" placeholder="{{item.parameterInfos[3]?.name}}" class="form-control">
                        </td>
                        <td style="width: 100px;">
                            <button type="button" (click)="ProcessByNameAndType('Balance', 'FDR')" class="btn btn-info btn-sm">Process</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>