import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DaycloseProcessRoutingModule } from './dayclose-process-routing.module';
import { DailyCloseComponent } from './daily-close/daily-close.component';
import { QuarterlyCloseComponent } from './quarterly-close/quarterly-close.component';
import { MonthlyCloseComponent } from './monthly-close/monthly-close.component';
import { HalfYearlyCloseComponent } from './half-yearly-close/half-yearly-close.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProcessAccountBalanceComponent } from './process-account-balance/process-account-balance.component';
import { BatchProcessComponent } from './batch-process/batch-process.component';
import { BatchProcessMonitorComponent } from './batch-process-monitor/batch-process-monitor.component';


@NgModule({
  declarations: [
    DailyCloseComponent,
    QuarterlyCloseComponent,
    MonthlyCloseComponent,
    HalfYearlyCloseComponent,
    ProcessAccountBalanceComponent,
    BatchProcessComponent,
    BatchProcessMonitorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DaycloseProcessRoutingModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    NgbDatepickerModule
  ]
})
export class DaycloseProcessModule { }
