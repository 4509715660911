<div class="row">
    <hr>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-10">
                <h5 class="card-title" style="color: #888888; font-family: Arial; font-size: 20px !important;">Loan Collection</h5>

                    </div>
                    <div class="col-2">
                        
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                  </button>
                    </div>
                </div>
                  <hr>
            </div>
            
            <div class="card-body">
                <div class="col-md-12">
                    <div class="form-group row" id="DateRangeSelect">
                        <label for="fromDate" class="col-sm-2 col-form-label text-left">From Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="fromDate" placeholder="Select From Date">
                        </div>
                        <label for="toDate" class="col-sm-2 col-form-label text-left">To Date <span class="redStar">*</span></label>
                        <div class="col-sm-4 input-group">
                            <input type="date" class="form-control" [(ngModel)]="toDate" placeholder="Select To Date">
                        </div>
                    </div>
                    
                    <div class="form-group row" style="margin-top: 10px;">
                        <label class="col-sm-8"></label>
                        <div class="col-sm-4">
                            <button class="btn btn-success" style="margin-left: 5px; float: right;" (click)="OpenPdf()">Print</button>
                            <button class="btn btn-info" style="margin-left: 5px; float: right;" (click)="OpenExcel()">Excel</button>
                            <button class="btn btn-warning" style="margin-left: 5px; float: right;" (click)="GetLoanCollection()">Load</button>
                        </div>
                    </div>
                </div>
    <div class="col-md-12 col-sm-12" *ngIf="collectionDataList">
        <table id="loanCollectionTable" class="table-bordered">
            <thead>
                <tr>
                    <th>Account No</th>
                    <th>Account Title</th>
                    <th>Disbursement Date</th>
                    <th>Loan Amount</th>
                    <th>Interest Amount</th>
                    <th>Total Disburse Amount</th>
                    <th>Collection Date</th>
                    <th>Collection Amount</th>
                    <th>Outstanding Amount</th>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let item of collectionDataList"> 
                            <td>{{item.accounT_NO}}</td>
                            <td>{{item.accounT_TITLE}}</td>
                            <td>{{formatDateString(item.disbursementDate)}}</td>
                            <td style="text-align: right !important;">{{item.loanAmount}}</td>
                            <td style="text-align: right !important;">{{item.interestAmount}}</td>
                            <td style="text-align: right !important;">{{item.loanAmount+item.interestAmount}}</td>
                            <td>{{formatDateString(item.collectionDate)}}</td>
                            <td style="text-align: right !important;">{{item.collectionAmount}}</td>
                            <td style="text-align: right !important;">{{item.outstanding}}</td>
                    </tr>
            </tbody>
        </table>
    </div>
                
            </div>
        </div>
    </div>
</div>
<br />


