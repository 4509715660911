import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: 'app-loan-overdue',
  templateUrl: './loan-overdue.component.html',
  styleUrls: ['./loan-overdue.component.scss']
})
export class LoanOverdueComponent implements OnInit {
  asOnDate:Date = new Date();
  reportBy:number;
  overdueList:any[];
 
  constructor(private pdfService:PdfService,
    private location: Location,
    private datePipe:DatePipe) { }

  ngOnInit(): void {
  }
  OpenPDF(){
    this.pdfService.LoanOverDueReportPdf(this.asOnDate).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

  OpenExcel(){
    this.pdfService.LoanOverDueReportExcel(this.asOnDate).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

  LoadData(){
    this.pdfService.LoanOverDueReportDataLoad(this.asOnDate).subscribe(res => {
      this.overdueList=res.body;
      console.log(this.overdueList)
    })
  }
  
  goBack() {
    this.location.back();
  }

  formatDateString(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, 'dd-MM-yyyy'); // Adjust the format as needed
    return formattedDate || dateString;
  }
}
