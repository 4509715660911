// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//const baseUrl = "https://localhost:44291"; //suza
//  const baseUrl = "https://localhost:44391";
//const baseUrl = "https://localhost:44395";

//const baseUrl = "http://115.127.99.114:2025";
const baseUrl = "https://eximapi.opuserp.com"; // exim bank live
//const baseUrl = "https://leancoreapi.opuserp.com";  ////Lean_Core

//const baseUrl = "https://demoapi.opuserp.com";  //// DemoLean_Core

//const baseUrl = "https://danaapileancore.opuserp.com";  ////Dana_Lean_Core
//const baseUrl = "https://115.127.99.114:444";

//const baseUrl = "http://115.127.99.114:2025";

export const environment = {
  production: true,
  baseUrl: baseUrl,


  registernurl: baseUrl + "/register",
  userurl: baseUrl + "/users",
  customerurl: baseUrl + "/users",
};
