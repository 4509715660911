
<!-- <div class="card-header">
  <h5 class="card-title" style="color: black;font-size: 23px !important;">Loan apply</h5>

</div> -->

<!-- <div class="card" style="width: 100%;">


  <div class="card-body">
    <h5 class="card-title" style="color: black">Loan apply</h5>
    <hr>
    <ul class="list-unstyled mb-0">
      <li class="d-inline-block mr-2">
        <div class="radio">
          <input type="radio" id="radio1" name="basic-radio-1" value="New" #option1
            (change)="onRadioChange(option1.value)">
          <label for="radio1" style="cursor: pointer;margin-left: 50px;" title="Please click here for loan apply">
            <h3 style="padding-top: 10%;">New Loan</h3>
          </label>
        </div>
      </li> 
       <li class="d-inline-block mr-2">
        <div class="radio">
          <input type="radio" name="basic-radio-1" id="radio2" value="Existing" #option2
            (change)="onRadioChange(option2.value)" />
          <label for="radio2" style="cursor: pointer;">
            <h3 style="padding-top: 7%;">Existing Loan</h3>
          </label>
        </div>
      </li> 
    </ul>
  </div>
</div> -->

<div class="card" *ngIf="selectedOption === 'New'">
<div class="row card-header  d-flex flex-row align-items-center">
  <div class="col-6">
  <h5 class="card-title" style="color: black">Loan apply</h5>
  </div>
  <div class="col-6">
    <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
      <!-- <i class="ft-chevrons-left"> Back</i> -->
      <i class="icon-arrow-left" style="font-size: 17px;"> </i>
    </button>
  </div>
</div>

<hr style="height: 10%;">
  <div class="card-body mt-1">
    
    
    <!-- <div class="row">
      <div class="col-md-9" style="margin-left: 50px;">
        <div class="form-group row">
          <label for="sortorder" class="col-sm-2 col-form-label">Loan Type</label>
          <div class="col-sm-8" style="padding-left: 7%">
            <ul class="list-unstyled mb-0">
              <li class="d-inline-block mr-2">
                <div class="radio radio-info">
                  <input type="radio" name="loanType" id="rbNanoLoan" value="3" [(ngModel)]="selectedLoanType" (change)="getNanoLoanProductInfoList(3)">
                  <label for="rbNanoLoan">Nano Loans</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio radio-success">
                  <input type="radio" name="loanType" id="rbTermLoan" value="1" [(ngModel)]="selectedLoanType" (change)="getLoanProductBuilderInfoList(1)">
                  <label for="rbTermLoan">Term Loans</label>
                </div>
              </li>
              <li class="d-inline-block mr-2">
                <div class="radio radio-secondary">
                  <input type="radio" name="loanType" id="rbContinuousLoan" value="2" [(ngModel)]="selectedLoanType" (change)="getLoanProductBuilderInfoList(2)">
                  <label for="rbContinuousLoan">Continuous Loans</label>
                </div>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
        <div class="col-md-6" style="margin-left: 50px;" >
          <div class="form-group row">
            <label for="sortorder" class="col-sm-4 col-form-label">Loan Tenure</label>
            <div class="col-sm-8">
              <select class="form-control" [(ngModel)]="selectedProductId" name="selectedProductId">
                <option value="">Select Tenure</option>
                <option *ngFor="let item of productList" [value]="item.id">
                {{item.loanProduct?.code}}- {{ item.duration?.monthName }} - Rate-{{item.loanProduct?.interestRate}}
                </option>
              </select>
            </div>
          </div>
        </div>


      <!-- <div class="col-md-6" style="margin-left: 50px;" *ngIf="selectedLoanType === '1' ||  selectedLoanType === '2'" >
        <div class="form-group row">
          <label for="sortorder" class="col-sm-4 col-form-label">Loan Product</label>
          <div class="col-sm-8">
            <select class="form-control" [(ngModel)]="selectedProductId" name="selectedProductId">
              <option value="">Select Product</option>
              <option *ngFor="let item of productList" [value]="item.id">
                {{ item.name }} - {{ item.code }}, Rate-{{item.interestRate}}
              </option>
            </select>
          </div>
        </div>
      </div> -->
      <div class="col-md-6 d-none">
        <div class="form-group row">
          <label for="sortorder" class="col-sm-4 col-form-label">Customer Name</label>
          <div class="col-sm-8">
            <select class="form-control" [(ngModel)]="selectedCustomerId" name="selectedCustomerId">
              <option *ngFor="let item of customerList" [value]="item.id">
                {{ item.customerNameEng }} - {{ item.mobileNo }} %
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-4 mb-3 mx-auto pull-right">
      
        <button class="btn " (click)="NewLoanGenerate('New')"
          style="background-color: #616D89;color:white;font-weight: bold; margin-right: 230px;">Apply</button>
       

          <!-- <button class="btn " (click)="CheckLaunderingCustomerByCustomerId()"
            style="background-color: #616D89;color:white;font-weight: bold; margin-right: 230px;">Apply</button> -->
      </div>
      </div>
      
     
    </div>


 
 
</div>


<!-- <div *ngIf="selectedOption === 'Existing'">
  <app-search _nghost-xyv-c325="" class="ng-star-inserted">
    <section _ngcontent-xyv-c325="" class="search-wrapper">
      <div _ngcontent-xyv-c325="" class="search-bar mt-3">
        <form _ngcontent-xyv-c325="" novalidate="" action="javascript:;" class="ng-untouched ng-pristine ng-valid"
          [formGroup]="frmGroup">
          <div _ngcontent-xyv-c325="" class="page-search-input form-group position-relative">
            <input _ngcontent-xyv-c325="" type="search" id="searchbar" placeholder="Search by loan code.."
              formControlName="searchValue" class="form-control rounded-right form-control-lg shadow pl-2">
            <button _ngcontent-xyv-c325="" style="background-color: #616D89;color:white;cursor: pointer;" type="button"
              [disabled]="!frmGroup.valid" (click)="search()"
              class="btn btn-primary search-btn rounded position-absolute mt-3">
              <span _ngcontent-xyv-c325="" class="d-none d-sm-block">Search</span>
              <i _ngcontent-xyv-c325="" class="ft-search d-block d-sm-none"></i>
            </button>
          </div>
        </form>
      </div>
    </section>
  </app-search>
</div> -->

<div class="card" style="width: 100%;" *ngIf="lgList && lgList.length > 0">
<div class="card" style="width: 100%;">
  <div class="card-body">
    <div class="container">
      <h5 class="card-title" style="color: black">Existing  Applied Loan Info</h5>
      <hr>
      <table id="example" class="table table-striped table-bordered" style="width:100%">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Temporary Application No</th>
            <th>Loan Tenure</th>
            <th style="width: 20%;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of lgList; let i = index;">
            <td>{{i + 1}}</td>
            <td>{{item?.leadCode}}</td>
            <td>{{item?.tenure?.monthName}}</td>
            <td style="width: 20%;">
              <button class="btn" (click)="goLoanForm(item?.id,item?.customerId,item?.loanProductId,item?.tenureId)"
                style="background-color: #616D89;color:white;font-weight: bold;"><i class="3x">Go to Form</i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>



