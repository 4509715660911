import { Component, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild, ComponentFactoryResolver, ViewContainerRef, ChangeDetectorRef  } from '@angular/core';
import { VoucherInfoService } from '../service/voucher-info.service';
import { SharedService } from 'app/services/shared.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { VoucherReportComponent } from 'app/pdf-reporting/voucher-report/voucher-report.component';
import { HttpClient } from '@angular/common/http';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DatePipe, Location } from "@angular/common";
@Component({
  selector: "app-voucher-master",
  templateUrl: "./voucher-master.component.html",
  styleUrls: ["./voucher-master.component.scss"],
})
export class VoucherMasterComponent implements OnInit {
  @ViewChild("dynamicDataContainer", { static: false })
  dynamicDataContainer: ElementRef;

  list: any[] = [];
  voucherDetailsInfo: any[] = [];
  voucherMaster: any[] = [];
  closeResult: string;
  voucherMasterList: any[] = [];
  voucherMasterListInfo: any[] = [];

  currentPage = 1;
  itemsPerPage = 10;
  currentItems = 0;
  totalItems = 0;
  searchValue = "";
  fromDate: Date = new Date();
  endDate: Date = new Date();
  noDataMessage: string = "No data available";
  formattedStartDate: string;
  formattedEndDate: string;
  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private _voucherServie: VoucherInfoService,
    private shared: SharedService,
    private router: Router,
    private modalService: NgbModal,
    private pdfService: PdfService,
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    //this.getVoucherMasterDetailsList();
    //this.getVoucherList();

    this.fromDate = this.fromDate || new Date();
    this.endDate = this.endDate || new Date();
    this.getVoucherMasterList();
  }

  formatDates() {
    this.formattedStartDate = this.datePipe.transform(
      this.fromDate,
      "dd-MM-yyyy"
    );
    this.formattedEndDate = this.datePipe.transform(this.endDate, "dd-MM-yyyy");
  }
  onDateChange() {
    this.formatDates();
  }
  getVoucherMasterDetailsList() {
    this._voucherServie.getVoucherMasterWithDetailsList().subscribe((res) => {
      this.list = res;
    });
  }

  //////////////////////////////
  // getVoucherList() {
  //   this._voucherServie.getVoucherMasterList().subscribe((res) => {
  //     this.voucherMaster = res;
  //   });
  // }

  getVoucherMasterList() {

     const formattedStartDate = this.datePipe.transform(
       this.fromDate,
       "yyyy-MM-dd"
     );
     const formattedEndDate = this.datePipe.transform(
       this.endDate,
       "yyyy-MM-dd"
     );

   
    this._voucherServie
      .getVoucherMasterListWithSearchAndPagination(
        this.currentPage,
        this.itemsPerPage,
        this.searchValue,
        //this.fromDate,
        //this.endDate
        formattedStartDate,
        formattedEndDate
      )
      .subscribe((res: any) => {
        console.log(res.data.length);
         this.voucherMasterList = res.data;
         this.totalItems = res.totalItems;
         this.currentItems = res.currentItems;
         this.cdr.detectChanges();

      });
  }

  // getVoucherMasterListWithDate() {
  //   this._voucherServie
  //     .getVoucherMasterListFromDateToDate(this.fromDate, this.endDate)
  //     .subscribe((res: any) => {
  //       console.log("searchData");
  //       console.log(res);
  //       if (res.length > 0) {
  //         this.dataShow = "search";
  //         this.voucherMasterListInfo = res;

  //         this.voucherMasterListInfo.forEach((item) => {
  //           item.voucherDate = new Date(item.voucherDate);
  //         });
  //         // this.voucherMasterListInfo.sort((a, b) => {
  //         //   return b.voucherDate.getTime() - a.voucherDate.getTime();
  //         // });
  //       } else {
  //          this.voucherMasterListInfo = [];
  //         this.noDataMessage = "No data available";

  //       }
  //     });
  // }

  getVoucherDetails(id: any) {
    this._voucherServie.getVoucherDetailsByMasterId(id).subscribe((data) => {
      this.voucherDetailsInfo = data;
    });
  }

  openVoucherDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  OpenPDF(voucherId) {
    this.pdfService.OpenVoucherPdf(voucherId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getVoucherMasterList();
  }

  onSearch() {
    this.currentPage = 1;
    this.getVoucherMasterList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getVoucherMasterList();
  }
  goBack() {
    this.location.back();
  }

}
