<form [formGroup]="frmGroup">
  <div class="col-md-12">
      <div class="card" style="width: 100%;">
        <div class="card-header">
            <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">NPSB</h5>
           
        </div>
        <div class="card-body" style="margin-top: 30px;">
                 
            <div class="row">

                <div class="col-md-6">

                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Debit Account</label>
                        <div class="col-sm-8">
                            <select class="form-control" formControlName="customerCode" [(ngModel)]="accountNoFund1"
                                (change)="loadUserDataByAccountNoFundFirst($event.target.value)">
                                <option value="">Select</option>
                                <ng-container *ngFor="let item of AccountNoList">
                                    <option [value]="item.id">{{item.customerAccountNo}} - {{item.accountType}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Current Balance</label>
                        <div class="col-sm-8">
                            <input type="text" style="text-align: right;" class="form-control"
                                formControlName="currentBalance" value="{{currentBalance}}" readonly>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label for="" class="col-form-label col-sm-4">Currency</label>
                        <div class="col-sm-8">
                            <select class="form-control" name="currencyId" formControlName="currencyId">
                                <option value="">Select</option>
                                <ng-container *ngFor="let item of countryList">
                                    <option [value]="item.id">{{item.currency}}</option>
                                </ng-container>
                            </select>

                        </div>

                    </div>

                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Amount</label>
                        <div class="col-sm-8">

                            <input type="text" style="text-align: right;" class="form-control"
                                formControlName="voucherAmount" placeholder="0" style="text-align: right"
                                (input)="frmGroup.get('voucherAmount').setValue(formatNumber($event.target.value))"
                                (keyup)="CheckBalance()" (keyup)="voucherAmountValue($event.target.value)">

                                <span class="text-danger" style="font-size: 10px; font-weight: bold;" *ngIf="!isvalidMax">Invalid Amount.
                                    Amount cannot be more than 25000.</span>
                            <span class="text-danger" style="font-size: 10px; font-weight: bold;"
                                *ngIf="!isvalid">Invalid Amount.
                                Amount cannot be more than the Balance.</span>
                            <span *ngIf="frmGroup.get('voucherAmount').hasError('pattern')" class="text-danger">
                                Only numeric values are allowed.
                            </span> 
 
                        </div>

                    </div>


                </div>
                <div class="col-md-6">
 
<div class="form-group row">
    <div class="col-6"></div>
    <div class="col-6">
        <button data-toggle="tooltip" (click)="AddBenificiary()" title="Save" type="button"
            class="btn btn-primary btn-sm" style="float:right; margin-top:5px;">Add Beneficiary
        </button>
    </div>
    </div>



                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Credit Account</label>
                        <div class="col-sm-8">
                            <select class="form-control" formControlName="customerCode" [(ngModel)]="accountNoFund2"
                                (change)="loadUserDataByAccountNoFundLast($event.target.value)">
                                <option value="">Select</option>
                                <ng-container *ngFor="let item of AccountNoList2">
                                    <option [value]="item.id">{{item.accountNumber}}</option>
                                </ng-container>
                            </select>

                        </div>
                    </div>



                    <div class="form-group row">
                        <label for="" class="col-sm-4 col-form-label">Bank Info</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" formControlName="customerName"
                                [value]="customerInfo_cashFundTransfer2" readonly>
                        </div>

                    </div>


                    <div class="form-group row">
                        <label for="remarks" class="col-sm-4 col-form-label">Remarks</label>
                        <div class="col-sm-8">
                            <textarea class="form-control" formControlName="remarks" id="" cols="30"
                                rows="2"></textarea>
                        </div>
                    </div>
 
                </div>  

            </div>

            <div class="form-group row">
                <div class="col-4"></div>
                <div class="col-4"><button data-toggle="tooltip" (click)="onsubmit()" *ngIf="isBtnValid"
                        title="Save" type="button" class="btn btn-success" style="float:right; margin-top:5px;">Send
                    </button>

                    <button type="button" title="Refresh" class="btn btn-secondary"
                        style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;"
                        (click)="ResetForm()">Refresh</button>
                </div>
                <div class="col-4"></div>

            </div>

        </div>
      </div>
  </div>
</form>