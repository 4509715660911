import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeadGenerationComponent } from './lead-generation/lead-generation.component';
import { LoanApplicationComponent } from './loan-application/loan-application.component';
import { BasicInfoSelfComponent } from './basic-info-self/basic-info-self.component';
import { CustomerLoanCreateComponent } from './customer-loan-create/customer-loan-create.component';
import { DisburseLeadInfoComponent } from './disburse-lead-info/disburse-lead-info.component';
import { DisburseCollectionComponent } from './disburse-collection/disburse-collection.component';
import { DynamicLoanApplicationComponent } from './dynamic-loan-application/dynamic-loan-application.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { LoanApplicationFullViewComponent } from './loan-application-full-view/loan-application-full-view.component';
import { LoanListComponent } from './loan-list/loan-list.component';
import { PartnersComponent } from './BNPL/partners/partners.component';
import { BnplPartnerComponent } from './BNPL/bnpl-partner/bnpl-partner.component';

const routes: Routes = [
  {
    path: "lead-generation",
    component: LeadGenerationComponent,
    data: {
      title: "Lead Generation",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "loan-application/:leadId/:customersId/:loanProductsId/:tenureId",
    component: LoanApplicationComponent,
    data: {
      title: "Lead Generation",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "customer-loan",
    component: CustomerLoanCreateComponent,
    data: {
      title: "customer-loan",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "disburse-lead-info/:leadId/:customersId/:loanProductsId",
    component: DisburseLeadInfoComponent,
    data: {
      title: "disburse-lead-info",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "disburse-collection",
    component: DisburseCollectionComponent,
    data: {
      title: "disburse-collection",
    },
    canActivate: [AuthGuard],
  },

  {
    path: "dynamic-loan-application/:leadId/:customersId/:loanProductsId",
    component: DynamicLoanApplicationComponent,
    data: {
      title: "Lead Generation",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "app-loan-application-full-view/:id",
    component: LoanApplicationFullViewComponent,
    data: {
      title: "Lead Application",
    },
    canActivate: [AuthGuard],
  },

  {
    path: "loan-list",
    component: LoanListComponent,
    data: {
      title: "loan-list",
    },
    canActivate: [AuthGuard],
  },

  {
    path: "bnpl-partner",
    component: BnplPartnerComponent,
    data: {
      title: "bnpl-partner",
    },
    canActivate: [AuthGuard],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoanApplicationRoutingModule { }
