import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: "app-final-disburse-list",
  templateUrl: "./final-disburse-list.component.html",
  styleUrls: ["./final-disburse-list.component.scss"],
})
export class FinalDisburseListComponent implements OnInit {
  dataList: any[] = [];
  status: number;
   startDate: Date = new Date();
   EndDate: Date = new Date();

  formattedStartDate: string;
  formattedEndDate: string;

  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private pdfService: PdfService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
   // this.getFinalDisburse();
     this.startDate = this.startDate || new Date();
     this.EndDate = this.EndDate || new Date();
     this.GetFinalDisbursementListByDate();
  }

  formatDates() {
    this.formattedStartDate = this.datePipe.transform(this.startDate, "dd-MM-yyyy");
    this.formattedEndDate = this.datePipe.transform(this.EndDate, "dd-MM-yyyy");
  }
  onDateChange() {
    this.formatDates();
  }
  // getFinalDisburse() {
  //   this.status = 7;
  //   this._serviceDashboard
  //     .getFinalDisbursLoan(this.status)
  //     .subscribe((res: any[]) => {
  //       this.dataList = res;
  //       this.changeDetectorRef.detectChanges();
  //     });
  // }

  OpenPDF(leadId) {
    this.pdfService.OpenSchedulePdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }
  GetFinalDisbursementListByDate() {
    const formattedStartDate = this.datePipe.transform(this.startDate,"yyyy-MM-dd");
    const formattedEndDate = this.datePipe.transform(this.EndDate, "yyyy-MM-dd");

    this._serviceDashboard
      .getFinalDisbursLoanByDate(formattedStartDate, formattedEndDate)
      .subscribe((res: any[]) => {
        this.dataList = res;
        this.changeDetectorRef.detectChanges();
      });
  }

  goBack() {
    this.location.back();
  }
  // ApplicationApproval(leadCode:string,type:number){
  //   this.router.navigate(['/loanapplication-approval/loanapplication-approval/'+ leadCode +'/'+ type]);
  // }
}