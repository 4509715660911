<div class="wrapper " id="loginsBackImage" >
  <div class="main-panel"   >
    <!-- BEGIN : Main Content-->
    <div class="main-content">
      <div class="content-overlay" ></div>
      <div class="content-wrapper p-0" >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<!-- style=" background-image: url('./assets/img/photos/money1.jpg'), linear-gradient(#D4E7C5, #008641);
background-size: cover;
background-position: center;
background-repeat: no-repeat;opacity: .8;
"-->
