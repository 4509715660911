import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProductBuilderComponent } from './product-builder/product-builder.component';
import { ProductBuilderEditComponent } from './product-builder/product-builder-edit/product-builder-edit.component';
import { LoanProductListComponent } from './loan-product/loan-product-list/loan-product-list.component';
import { LoanProductEntryComponent } from './loan-product/loan-product-entry/loan-product-entry.component';
import { LoanProductDetailsComponent } from './loan-product/loan-product-details/loan-product-details.component';
import { ProductBuilderListComponent } from './product-builder/product-builder-list/product-builder-list.component';
import { AuthGuard } from 'app/shared/auth/auth-guard.service';
import { PendingProductComponent } from './loan-product/pending-product/pending-product.component';
import { PendingRetailProductComponent } from './product-builder/pending-retail-product/pending-retail-product.component';
import { BnplProductEntryComponent } from './BnplProduct/bnpl-product-entry/bnpl-product-entry.component';
import { BnplProductDetailsComponent } from './BnplProduct/bnpl-product-details/bnpl-product-details.component';
import { BnplProductListComponent } from './BnplProduct/bnpl-product-list/bnpl-product-list.component';
import { BnplProductPendingComponent } from './BnplProduct/bnpl-product-pending/bnpl-product-pending.component';
 
const routes: Routes = [
  {
    path: "product-builder/:id",
    component: ProductBuilderComponent,
    data: {
      title: "product-builder",
    },
    canActivate:[AuthGuard]
  },
  {
    path: "product-builder-edit",
    component: ProductBuilderEditComponent,
    data: {
      title: "product-builder",
    },
    canActivate:[AuthGuard]
  },
  {
    path: "loan-product-list",
    component: LoanProductListComponent,
    data: {
      title: "loan-product-builder",
    },
    canActivate:[AuthGuard]
  },
  {
    path: "pending-product-list",
    component: PendingProductComponent,
    data: {
      title: "pending-product-list",
    },
    canActivate:[AuthGuard]
  },
  {
    path: "pending-retial-product",
    component: PendingRetailProductComponent,
    data: {
      title: "pending-retial-product",
    },
    canActivate:[AuthGuard]
  },
  {
    path: "loan-product-entry/:id",
    component: LoanProductEntryComponent,
    data: {
      title: "loan-product-builder",
    },
    canActivate:[AuthGuard]
  },
  {
    path: "loan-product-details",
    component: LoanProductDetailsComponent,
    data: {
      title: "loan-product-builder",
    },
    canActivate:[AuthGuard]
  },

  {
    path: "product-builder-list",
    component: ProductBuilderListComponent,
    data: {
      title: "loan-product-builder",
    },
    canActivate:[AuthGuard]
  },

  {
    path: "bnpl-product-entry/:id",
    component: BnplProductEntryComponent,
    data: {
      title: "bnpl-product-builder",
    },
    canActivate:[AuthGuard]
  },
  {
    path: "bnpl-product-details",
    component: BnplProductDetailsComponent,
    data: {
      title: "bnpl-product-builder",
    },
    canActivate:[AuthGuard]
  },

  {
    path: "bnpl-product-list",
    component: BnplProductListComponent,
    data: {
      title: "bnpl-product-list",
    },
    canActivate:[AuthGuard]
  },

  {
    path: "bnpl-product-pending",
    component: BnplProductPendingComponent,
    data: {
      title: "bnpl-product-pending",
    },
    canActivate:[AuthGuard]
  },

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ProductRoutingModule { }
