import {
  ChangeDetectorRef,
  Component,
  OnInit,
  EventEmitter,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DistrictService } from "app/master-data/basic/basic-service/district.service";
import { DivisionService } from "app/master-data/basic/basic-service/division.service";
import { UpazilaService } from "app/master-data/basic/basic-service/upazila.service";
import { SharedService } from "app/services/shared.service";
import { ProfessionalDetailService } from "../professional-detail.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LeadGenerationServiceService } from "../lead-generation-service.service";
import { DatePipe } from "@angular/common";
import { CustomerService } from "app/customer/customer.service";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Address } from "app/customer/model/address";

@Component({
  selector: "app-address-info",
  templateUrl: "./address-info.component.html",
  styleUrls: ["./address-info.component.scss"],
})
export class AddressInfoComponent implements OnInit {
  frmGroupAddress: FormGroup;
  model: any = {};
  //model: Address[] = [];
  clientInfoId: any = 0;
  Id: number;
  leadId: any = 0;
  divisionId: number;
  districtId: number;
  upazilaId: number;
  preThanaId: number=0;
  permanentDivisionId: number;
  permanentDistrictId: number;
  permanentUpazilaId: number;
  customerDetailsInfos: any;
  list: any[] = [];
  AddressList: any[] = [];
  DivisionList: any[] = [];
  DivisionList2: any[] = [];
  DistrictList: any[] = [];
  DistrictList2: any[] = [];
  DistrictByDivList: any[] = [];
  DistrictByDivList2: any[] = [];
  ThanaByDisList: any[] = [];
  ThanaByDisList2: any[] = [];
  ThanaListByDis: any[] = [];
  ThanaListByDis2: any[] = [];
  Village: any[] = [];
  Village2: any[] = [];
  unionWard: any[] = [];
  unionWard2: any[] = [];
  ThanaList: any[] = [];
  postOffice: string;
  postCode: string;
  postCodePermanent: string;
  postOfficePermanent: string;
  customersId: any = 0;
  isAuto: boolean = false;
  isDataNull: boolean = false;
  districtName: string;
  same: number;
  profileUpdate: any;
  permanentAddressData: any = [];
  presentAddressData: any = [];
  isEdit: boolean = false;
  saveSuccess: boolean = false;
  @Output() addressUpdateSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private _serviceDivi: DivisionService,
    private _serviceDistrict: DistrictService,
    private _serviceUpazila: UpazilaService,
    private _serviceProfession: ProfessionalDetailService,
    private _leadGennarationService: LeadGenerationServiceService,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private activeRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private customerService: CustomerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getDivisionInfoList();
    this.getDivisionInfoList2();
    this.AddresspopulateForm();
    //this.LoadDistrictList(this.districtName);

    //this.getAddressInfoList();
    this.getDistrictList();
    this.getDistrictList2();
    //this.getLeadInfo();
    this.GetCustomerDetailsForEdit();
    this.route.paramMap.subscribe((params) => {
      if (params.get("id") == null) {
        this.customerService
          .GetCustomerDetailsForEdit()
          .subscribe((data: any) => {
            this.customersId = data.customerDetailsInfos.customerId;
            this.LoadAddressInfo(this.customersId);
          });
      } else {
        this.customersId = parseInt(params.get("id"), 10);
        this.LoadAddressInfo(this.customersId);
      }
    });
  }

  LoadAddressInfo(customersId: number) {
    //this._serviceProfession.GetLeadGenerationByLeadNoforAddress(customersId).subscribe(res => {}
    this._serviceProfession
      .GetPresentAndPermanentAddressByCustomerid(customersId)
      .subscribe((res) => {
        console.log(res)
        if (res.parAddressId >0 || res.preAddressId>0) {
          this.presentAddressData = res;
          this.divisionId = res.preDivisionsId;
          this.districtId = res.preDistrictsId;
          //this.upazilaId = res.preUpazilaId;

          if (this.divisionId > 0) {
            this.getDistrictInfoList(res.preDivisionsId);
          }

          if (this.districtId > 0) {
            this.getThanaListByDistrictId(res.preDistrictsId);
          }
          if(res.prePSId>0){
            this.getUnionWardListByThanaId(res.prePSId)
          }
          if(res.preUnionWardId>0){
            this.getVillageListByUninWardId(res.preUnionWardId)
          }
          this.permanentAddressData = res;
          this.permanentDivisionId = res.parDivisionsId;
          this.permanentDistrictId = res.parDistrictsId;
          this.permanentUpazilaId = res.parUpazilaId;

          if (this.permanentDivisionId > 0) {
            this.getDistrictInfoList2(res.parDivisionsId);
          }

          if (this.permanentDistrictId > 0) {
            this.getUpazilaListByDistrictId2(res.parDistrictsId);
          }
          if (res.parPSId > 0) {
            this.getThanaListByDistrictId2(res.parDistrictsId);
          }
          if(res.parPSId>0){
            this.getUnionWardListByThanaId2(res.parPSId)
          }
          if(res.parUnionWardId>0){
            this.getVillageListByUninWardId2(res.parUnionWardId)
          }
          this.changeDetectorRef.detectChanges;
        } 
        else {
          this.isDataNull = true;
          this.AddresspopulateForm();
          // this.frmGroupAddress = this.formBuilder.group({
          //   presentId: 0,
          //   permanentId: 0,
          //   customerId: 0,
          //   DivisionsId: 0,
          //   DistrictsId: 0,
          //   upazilaId: 0,
          //   PresentAddress: "",
          //   PermamentAddress: "",
          //   postOffice: "",
          //   postCode: "",
          //   blockSector: "",
          //   HouseNo: "",
          //   RoadNo: "",
          //   permanentDivisionsId: 0,
          //   permanentDistrictsId: 0,
          //   permanentUpazilaId: 0,
          //   permanentPostOffice: "",
          //   permanentPostCode: "",
          //   permanentBlockSector: "",
          //   permanentHouseNo: "",
          //   permanentRoadNo: "",
          // });
        }
        this.changeDetectorRef.detectChanges;
      });
  }

  getLeadInfo() {
    this.activeRoute.paramMap.subscribe((params) => {
      const leadGNId = +params.get("leadId");
      this.leadId = +params.get("leadId");
      if (leadGNId != null && this.leadId > 0) {
        this._leadGennarationService.searchLeadCodebyId(leadGNId).subscribe(
          (res: any) => {
            this.clientInfoId = res.customerId;
            this.LoadAddressInfo(this.clientInfoId);
          },
          (error: any) => {
            this.shared.ShowAlert("Error", "Failed to retrieve.", "error");
          }
        );
      }
    });
  }

  AddresspopulateForm() {
    this.frmGroupAddress = this.formBuilder.group({
      presentId: [0],
      permanentId: [0],
      // id: [0],
      customersId: [0],
      DivisionsId: [0],
      DistrictsId: [0],
      thanaId: [0],
      preUnionWardId: [0],
      preVillageId: [0],
      upazilaId: [""],
      postOffice: [""],
      postCode: [""],
      blockSector: [""],
      HouseNo: [""],
      RoadNo: [""],
      permanentDivisionsId: [0],
      permanentDistrictsId: [0],
      permanentThanaId: [0],
      perUnionWardId: [0],
      perVillageId: [0],
      permanentUpazilaId: [""],
      permanentPostOffice: [""],
      permanentPostCode: [""],
      permanentBlockSector: [""],
      permanentHouseNo: [""],
      permanentRoadNo: [""],
      type: [""],
      PresentAddress: [""],
      PermamentAddress: [""],
    });
  }

  onAddresssubmit() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save this Address Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save Address!",
    }).then((res) => {
      if (res.isConfirmed) {

        if (this.customersId > 0) {
          this.model.presentId = this.frmGroupAddress.value.presentId;
          this.model.customersId = this.frmGroupAddress.value.customersId;
          this.model.DivisionsId = this.frmGroupAddress.value.DivisionsId;
          this.model.DistrictsId = this.frmGroupAddress.value.DistrictsId;
          this.model.upazilaId =0// this.frmGroupAddress.value.upazilaId;
          this.model.postOffice = this.frmGroupAddress.value.postOffice;
          this.model.postCode = this.frmGroupAddress.value.postCode;
          this.model.blockSector = this.frmGroupAddress.value.blockSector;
          this.model.HouseNo = this.frmGroupAddress.value.HouseNo;
          this.model.RoadNo = this.frmGroupAddress.value.RoadNo;
          this.model.PresentAddress = this.frmGroupAddress.value.PresentAddress;
          this.model.presentType = "present";

          this.model.permanentId = this.frmGroupAddress.value.permanentId;
          this.model.permanentDivisionsId =
            this.frmGroupAddress.value.permanentDivisionsId;
          this.model.permanentDistrictsId =
            this.frmGroupAddress.value.permanentDistrictsId;
          this.model.permanentUpazilaId =0;
            //this.frmGroupAddress.value.permanentUpazilaId;
          this.model.permanentPostOffice =
            this.frmGroupAddress.value.permanentPostOffice;
          this.model.permanentPostCode =
            this.frmGroupAddress.value.permanentPostCode;
          this.model.permanentBlockSector =
            this.frmGroupAddress.value.permanentBlockSector;
          this.model.permanentHouseNo =
            this.frmGroupAddress.value.permanentHouseNo;
          this.model.permanentRoadNo =
            this.frmGroupAddress.value.permanentRoadNo;
          this.model.PermamentAddress =
            this.frmGroupAddress.value.PermamentAddress;
          this.model.permanetType = "permanent";

          // this.model.customerID = this.clientInfoId  ;

          this.model.CustomersId = this.customersId;
          this.model.presentPoliceStationId=this.frmGroupAddress.value.thanaId;
          this.model.presentUnionWardId=this.frmGroupAddress.value.preUnionWardId;
          this.model.presentVillageId=this.frmGroupAddress.value.preVillageId;
          this.model.permanentPoliceStationId=this.frmGroupAddress.value.permanentThanaId;
          this.model.permanentUnionWardId=this.frmGroupAddress.value.perUnionWardId;
          this.model.permanentVillageId=this.frmGroupAddress.value.perVillageId;
          console.log(this.model)
          this._serviceProfession.saveAddress(this.model).subscribe((res) => {
            this.shared.ShowAlert(
              "Thank you...",
              "You save succesfully!",
              "success"
            );
            this.LoadAddressInfo(this.customersId);
            this.isDataNull = false;
            this.addressUpdateSuccess.emit();
            this.getProfileUpdateInfo();
            this.saveSuccess = true;
          });
        } else {
          this.shared.ShowAlert(
            "Warning...",
            "First Create a Client application!",
            "warning"
          );
          this.Addressreset();
        }
      } else if (res.isDismissed) {
        this.router.navigate(["/customer/customer-edit"]);
      }
    });
  }

  Addressreset() {
    this.AddresspopulateForm();
    this.model = {};
  }

  getDivisionInfoList() {
    this._serviceDivi.getlist().subscribe((res) => {
      this.DivisionList = res;

      this.changeDetectorRef.detectChanges;
    });
  }

  getDistrictList() {
    this._serviceDistrict.getlist().subscribe((res) => {
      if (res) {
        this.DistrictList = res;
        this.changeDetectorRef.detectChanges;
      }
    });
  }

  onDivisionChange(divisionId: any): void {
    if (divisionId > 0 || divisionId != null || divisionId != "") {
      this.getDistrictInfoList(divisionId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onDistrictChange(districtId: any): void {
    if (districtId > 0 || districtId != null || districtId != "") {
      this.getUpazilaListByDistrictId(districtId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onDistrictChangeForThana(districtId: any): void {
    if (districtId > 0 || districtId != null || districtId != "") {
      this.getThanaListByDistrictId(districtId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onThanaChangeForVillage(thanaId: any): void {
    if (thanaId > 0 || thanaId != null || thanaId != "") {
      this.getVillageListByThanaId(thanaId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onThanaChangeForVillage2(thanaId: any): void {
    if (thanaId > 0 || thanaId != null || thanaId != "") {
      this.getVillageListByThanaId2(thanaId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onThanaChangeForUnionWard(thanaId: any): void {
    if (thanaId > 0 || thanaId != null || thanaId != "") {
      this.getUnionWardListByThanaId(thanaId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onThanaChangeForUnionWard2(thanaId: any): void {
    if (thanaId > 0 || thanaId != null || thanaId != "") {
      this.getUnionWardListByThanaId2(thanaId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onUnionWardChangeForVillage(unionWardId: any): void {
    if (unionWardId > 0 || unionWardId != null || unionWardId != "") {
      this.getVillageListByUninWardId(unionWardId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onUnionWardChangeForVillage2(unionWardId: any): void {
    if (unionWardId > 0 || unionWardId != null || unionWardId != "") {
      this.getVillageListByUninWardId2(unionWardId);
      this.changeDetectorRef.detectChanges;
    }
  }
  getDistrictInfoList(divisionId: number): void {
    this._serviceDistrict.getDistrictByDivisionId(divisionId).subscribe(
      (res: any) => {
        this.DistrictByDivList = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading District:", error);
      }
    );
  }

  getDivisionInfoList2() {
    this._serviceDivi.getlist().subscribe((res) => {
      this.DivisionList2 = res;
      this.changeDetectorRef.detectChanges;
    });
  }

  getDistrictList2() {
    this._serviceDistrict.getlist().subscribe((res) => {
      if (res) {
        this.DistrictList2 = res;
        this.changeDetectorRef.detectChanges;
      }
    });
  }

  onDivisionChange2(divisionId: any): void {
    if (divisionId > 0 || divisionId != null || divisionId != "") {
      this.getDistrictInfoList2(divisionId);
      this.changeDetectorRef.detectChanges;
    }
  }
  onDistrictChange2(districtId: any): void {
    if (districtId > 0 || districtId != null || districtId != "") {
      this.getUpazilaListByDistrictId2(districtId);
      this.changeDetectorRef.detectChanges;
    }
  }
  
  onDistrictChangeForThana2(districtId: any): void {
    if (districtId > 0 || districtId != null || districtId != "") {
      this.getThanaListByDistrictId2(districtId);
      this.changeDetectorRef.detectChanges;
    }
  }
  getDistrictInfoList2(permanentDivisionId: number): void {
    this._serviceDistrict
      .getDistrictByDivisionId2(permanentDivisionId)
      .subscribe(
        (res: any) => {
          this.DistrictByDivList2 = res;
          this.changeDetectorRef.detectChanges;
        },
        (error: any) => {
          console.error("Error loading District:", error);
        }
      );
  }

  getUpazilaListByDistrictId(districtId: number): void {
    this.ThanaByDisList=[];
    this._serviceUpazila.getUpazilaByDistrictId(districtId).subscribe(
      (res: any) => {
        this.ThanaByDisList = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getThanaListByDistrictId(districtId: number): void {
    this.ThanaListByDis=[]
    this._serviceUpazila.getThanaByDistrictId(districtId).subscribe(
      (res: any) => {
        this.ThanaListByDis = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getThanaListByDistrictId2(districtId: number): void {
    this.ThanaListByDis2=[]
    this._serviceUpazila.getThanaByDistrictId(districtId).subscribe(
      (res: any) => {
        this.ThanaListByDis2 = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getVillageListByThanaId(thanaId: number): void {
    alert(thanaId)
    this.Village=[]
    this._serviceUpazila.getVillageListByThana(thanaId).subscribe(
      (res: any) => {
        this.Village = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getVillageListByThanaId2(thanaId: number): void {
    this.Village2=[]
    this._serviceUpazila.getVillageListByThana(thanaId).subscribe(
      (res: any) => {
        this.Village2 = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getUnionWardListByThanaId(thanaId: number): void {
    this.unionWard=[]
    this._serviceUpazila.getUnionWordListByThana(thanaId).subscribe(
      (res: any) => {
        this.unionWard = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getUnionWardListByThanaId2(thanaId: number): void {
    this.unionWard2=[]
    this._serviceUpazila.getUnionWordListByThana(thanaId).subscribe(
      (res: any) => {
        this.unionWard2 = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getVillageListByUninWardId(uninWardId: number): void {
    this.Village=[];
    this._serviceUpazila.getVillageListByUnionWord(uninWardId).subscribe(
      (res: any) => {
        this.Village = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getVillageListByUninWardId2(uninWardId: number): void {
    this.Village2=[];
    this._serviceUpazila.getVillageListByUnionWord(uninWardId).subscribe(
      (res: any) => {
        this.Village2 = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }
  getUpazilaListByDistrictId2(districtId: number): void {
    this.ThanaByDisList2=[];
    this._serviceUpazila.getUpazilaByDistrictId(districtId).subscribe(
      (res: any) => {
        this.ThanaByDisList2 = res;
        this.changeDetectorRef.detectChanges;
      },
      (error: any) => {
        console.error("Error loading upazila:", error);
      }
    );
  }

  GetCustomerDetailsForEdit() {
    this.customerService.GetCustomerDetailsForEdit().subscribe((data: any) => {
      this.customerDetailsInfos = data;

      this.clientInfoId = data.customerDetailsInfos.customerId;
      this.changeDetectorRef.detectChanges();
    });
  }

  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe((res) => {
      this.profileUpdate = res;
    });
    //this.changeDetectorRef.detectChanges();
  }

  getSameAddress(event: any) {
    if (event.target.checked) {
      this.frmGroupAddress.patchValue({
        permanentRoadNo: this.frmGroupAddress.get("RoadNo").value,
        permanentHouseNo: this.frmGroupAddress.get("HouseNo").value,
        permanentBlockSector: this.frmGroupAddress.get("blockSector").value,
        permanentPostCode: this.frmGroupAddress.get("postCode").value,
        permanentPostOffice: this.frmGroupAddress.get("postOffice").value,
        permanentDivisionsId: this.frmGroupAddress.get("DivisionsId").value,
        permanentDistrictsId: this.frmGroupAddress.get("DistrictsId").value,
        permanentUpazilaId: this.frmGroupAddress.get("upazilaId").value,
        PermamentAddress: this.frmGroupAddress.get("PresentAddress").value,
      });

      if (this.frmGroupAddress.get("DivisionsId").value > 0) {
        this.getDistrictInfoList2(
          this.frmGroupAddress.get("DivisionsId").value
        );
      }

      if (this.frmGroupAddress.get("DistrictsId").value > 0) {
        this.getUpazilaListByDistrictId2(
          this.frmGroupAddress.get("DistrictsId").value
        );
      }
    }
     else {
      this.frmGroupAddress.patchValue({
        permanentRoadNo: "",
        permanentHouseNo: "",
        permanentBlockSector: "",
        permanentPostCode: "",
        permanentPostOffice: "",
        permanentDivisionsId: "0",
        permanentDistrictsId: "0",
        permanentUpazilaId: "0",
        PermamentAddress: "",
      });

      this.LoadAddressInfo(this.customersId);
      this.changeDetectorRef.detectChanges();
    }
  }
}
