import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";
import { LoanProductService } from 'app/product/service/loan-product.service';
import { observable } from 'core-js/fn/symbol';

@Component({
  selector: 'app-loan-disbursement',
  templateUrl: './loan-disbursement.component.html',
  styleUrls: ['./loan-disbursement.component.scss']
})
export class LoanDisbursementComponent implements OnInit {

  fromDate:Date=new Date();
  toDate:Date=new Date();
  mfsType:number=0;
  mfsAccountList:any[];
  disbursementList:any[];
  constructor(
    private pdfservice:PdfService,
    private location:Location,
    private loanProductService:LoanProductService,
    private changeDetectorRef:ChangeDetectorRef,
    private datePipe:DatePipe) 
  { }

  ngOnInit(): void {
    this.getMFSList();
  }

  OpenPdf(){
    this.pdfservice.LoanDisbursementReport(this.fromDate,this.toDate,this.mfsType).subscribe(x=>{
      let blob:Blob=x.body as Blob;
      let url=window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
  OpenExcel(){
    this.pdfservice.LoanDisbursemetExcelReport(this.fromDate,this.toDate,this.mfsType).subscribe(x=>{
      let blob:Blob=x.body as Blob;
      let url=window.URL.createObjectURL(blob);

      window.open(url);
    })
  }

  goBack() {
    this.location.back();
  }

  getMFSList() {
    this.loanProductService.getMFSAccountList().subscribe((res) => {
      this.mfsAccountList = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  LoanData(){
    this.pdfservice.LoadDisbursementData(this.fromDate,this.toDate,this.mfsType).subscribe(res=>{
        this.disbursementList=res.body;
    })
  }

  formatDateString(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, 'dd-MM-yyyy'); // Adjust the format as needed
    return formattedDate || dateString;
  }
}
