<div class="card" style="width: 100%;">
<div class="row card-header  d-flex flex-row align-items-center">
  <div class="col-6">
      <h5 class="card-title" style="color: black">Voucher List</h5>
  </div>
  <div class="col-6">
    <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
      <!-- <i class="ft-chevrons-left"> Back</i> -->
      <i class="icon-arrow-left" style="font-size: 17px;"> </i>
    </button>
  </div>
</div>
<hr>

  <div class="card-body">
    <div class="container">
  
      <div class="row">
        <div class="col-md-6" style="text-align: left;">
          <select type="number" class="form-control mb-3" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
          </select>
        </div>
        <!-- <div class="col-md-6 form-group" style="text-align: right;">
          <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchValue"
          (keyup)="onSearch()">
        </div> -->
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="fromDate" class="col-sm-2 col-form-label text-left">From Date</label>
            <div class="col-sm-3 input-group">
              <input type="date" class="form-control"  [(ngModel)]="fromDate"  (ngModelChange)="onDateChange()" placeholder="Select From Date">
            </div>
            <label for="endDate" class="col-sm-2 col-form-label text-left">To Date</label>
            <div class="col-sm-3 input-group">
              <input type="date" class="form-control"  [(ngModel)]="endDate" (ngModelChange)="onDateChange()"  placeholder="Select End Date">
            </div>
            <div class="col-sm-2">
              <button  class="btn btn-success" (click)="getVoucherMasterList()" style="margin-left: 5px; float: right">Search</button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="noDataMessage; else dataAvailable">
        <p>{{ noDataMessage }}</p>
      </div> -->
      
      <!-- <ng-template #dataAvailable></ng-template> -->
      <div >
        <table id="example" class="table table-striped table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="width: 10%;">SL No.</th>
              <th>Voucher No </th>
              <th>Voucher Amount </th>
              <th>Voucher Date </th>
              <th>Voucher Type </th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of voucherMasterList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index">
              <td>{{i + 1}}</td>
              <td>{{item.voucherNo}}</td>
              <td>{{item.voucherAmount}}</td>
              <td>{{item.voucherDate | date:'yyyy-MM-dd'}}</td>
              <td>{{item.voucherType?.voucherTypeName}}</td>
        
        
              <td>
                <!-- <ul>
                            <li *ngFor="let detail of item.voucherDetails">
                              <p>Amount :{{detail.amount}} </p>
                              <p>Transection Mode: {{detail.transectionMode?.modeName}}</p> 
                               <p>GL: {{detail.ledgerRelation?.ledger?.accountName}}</p>
                              
                            </li>
                          </ul> -->
        
        
                <button class="btn btn-info btn-sm mb-1" style="margin-left: 5px;width:100%;"
                  (click)="openVoucherDetailsModal(voucherDetails)" (click)="getVoucherDetails(item.id)">Details</button>
                <button class="btn btn-success btn-sm mb-1" style="margin-left: 5px;width:100%" (click)="OpenPDF(item.id)">Print
                  View</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6" style="text-align: left;">
            <span>Showing {{currentItems}} of {{totalItems}} entries</span>
          </div>
          <div class="col-md-6" style="text-align: right;">
            <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
          </div>
        </div>
      </div>
      

      
      
    </div>
  </div>
</div>



<ng-template #voucherDetails let-c="close" let-d="dismiss">
  <div class="modal-dialog">

    <div class="modal-content" style="margin-left: -50%; width: 200%;">
      <div class="modal-header">
        <h4 class="modal-title">Voucher Details</h4>
        <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <table id="example" class="table table-striped table-bordered" style="width:100%">
        <thead>
          <tr>
            <th style="width: 10%;">SL No.</th>
            <th> Amount </th>
            <th>Transection Mode </th>
            <th>GL </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of voucherDetailsInfo; let ii = index">
            <td>{{ii + 1}}</td>
            <td>{{data.amount}}</td>
            <td>{{data.transectionMode.modeName}}</td>
            <td>{{data.ledgerRelation?.ledger?.accountName}}</td>
          </tr>
        </tbody>
      </table>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      </div>
    </div>
  </div>
</ng-template>