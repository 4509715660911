<div class="card" style="width: 100%;">
  <div class="card-header">
    <h5 class="card-title" style="color: black;font-family: Arial;font-size: 20px !important;">Loan Guarantor</h5>         
  </div>
    <div class="card-body">
      <div class="">
         
        <form [formGroup]="frmLoanGurantor">
          
           
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-12">
              <h5 class="card-title" style="color: black; font-size:15px">Search Guarantor(NID or Mobile Number if any  exist) </h5>
        <hr>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="nidNo" class="col-sm-4 col-form-label">NID No Search</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control"  (keyup)="onNIDgetGuarantor($event)"
                    placeholder="Type NID no...">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="mobile" class="col-sm-4 col-form-label">Mobile No Search</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" 
                    (keyup)="onMobileGetGuarantor($event)" placeholder="Type mobile no...">
                </div>
              </div>
            </div>
          </div>
          <hr>

          <div class="row">

            <div class="col-md-6">
              <div class="form-group row">
                <label for="type" class="col-sm-4 col-form-label">Guarantor Type</label>
                <div class="col-sm-8">
                  <select class="form-control" (change)="onGuarantorTypeChange($event.target.value)">
                    <option value="">Select type</option>
                    <option value="Business Guarantor">Business Guarantor</option>
                    <option value="Family Guarantor">Family Guarantor</option>
                  </select>
                </div>
              </div>
            </div>

          </div>

  <div class="row">
    
    <div class="col-md-6">
      <div class="form-group row">
        <label for="nidNo" class="col-sm-4 col-form-label">NID No</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="nidNo" 
            placeholder="Type NID no...">
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label for="mobile" class="col-sm-4 col-form-label">Mobile No</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="mobile"
            placeholder="Type mobile no...">
        </div>
      </div>
    </div>
  </div>


          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">Guarantor Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="name" placeholder="Type name...">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="fathersName" class="col-sm-4 col-form-label">Fathers Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fathersName" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="mothersName" class="col-sm-4 col-form-label">Mothers Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="mothersName" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="spouseName" class="col-sm-4 col-form-label">Spouse Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="spouseName" placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="dob" class="col-sm-4 col-form-label">Date of Birth</label>
                <div class="col-sm-8">
                  <input type="date" class="form-control" formControlName="dob"  name="dob"placeholder="Type here...">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="relationwithApplicant" class="col-sm-4 col-form-label">Relationship with applicant</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="relationwithApplicant"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="presentResidentialAddress" class="col-sm-4 col-form-label">Residential Address</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="presentResidentialAddress"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="permanentAddress" class="col-sm-4 col-form-label">Present Address</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="permanentAddress"
                    placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="telephone" class="col-sm-4 col-form-label">Telephone No.</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="telephone" placeholder="Type here...">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="fax" class="col-sm-4 col-form-label">Fax No.</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="fax" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="email" class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="email" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="tinNo" class="col-sm-4 col-form-label">TIN No</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="tinNo" placeholder="Type here....">
                </div>
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group row">
                <label for="occupation" class="col-sm-4 col-form-label">Occupation</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="occupation" placeholder="Type here...">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="businessAddress" class="col-sm-4 col-form-label">Business Address</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="businessAddress"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="employersName" class="col-sm-4 col-form-label">Employee Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="employersName" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="employersDesignation" class="col-sm-4 col-form-label">Employee Designation</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="employersDesignation"
                    placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="status" class="col-sm-4 col-form-label">Status</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="status">
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="netMonthlyIncome" class="col-sm-4 col-form-label">Net Monthly Income</label>
                <div class="col-sm-8">
                  <input type="text"  class="form-control" formControlName="netMonthlyIncome" style="text-align: right;"
                  (input)="frmLoanGurantor.get('netMonthlyIncome').setValue(formatNumber($event.target.value))"  placeholder="0">
                  <span *ngIf="frmLoanGurantor.get('netMonthlyIncome').hasError('pattern')" class="text-danger"> Only numeric values are allowed. </span> 
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="commentsSource" class="col-sm-4 col-form-label">Comments Source</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="commentsSource"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="commentsBSMM" class="col-sm-4 col-form-label">Comments BSMM</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="commentsBSMM" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="commentsBM" class="col-sm-4 col-form-label">Comments BM</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="commentsBM" placeholder="Type here....">
                </div>
              </div>
            </div> -->

            <!-- <div class="col-md-6">
              <div class="form-group row">
                <label for="commentsAVC" class="col-sm-4 col-form-label">Comments AVC</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="commentsAVC" placeholder="Type here...">
                </div>
              </div>
            </div> 
            <div class="col-md-6">
              <div class="form-group row">
                <label for="status" class="col-sm-4 col-form-label">Residential Status</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="status" placeholder="Type here....">
                </div>
              </div>
            </div>-->

            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="professionOfBG" class="col-sm-4 col-form-label">Profession Of BG</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="professionOfBG"
                    placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="age" class="col-sm-4 col-form-label">Guarantor Age</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="age" placeholder="0">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="maritalStatus" class="col-sm-4 col-form-label">Marital Status</label>
                <div class="col-sm-8">
                  <select class="form-control">
                    <option value="">Select marital Status</option>
                    <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="presentAddress" class="col-sm-4 col-form-label">Present address</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="presentAddress"
                    placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="years" class="col-sm-4 col-form-label">Years</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="years" placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="residentialStaus" class="col-sm-4 col-form-label">Residential Status</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="residentialStaus"
                    placeholder="Type here...">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="districtId" class="col-sm-4 col-form-label">District Name</label>
                <div class="col-sm-8">
                  <select class="form-control"  formControlName="districtId"  (change)="onDistrictChange($event.target.value)" >
                    <option value="">Select</option>
                    <option *ngFor="let item of districtLists" [value]="item.id">{{ item.districtName }}</option>

                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="thanaId" class="col-sm-4 col-form-label">Thana Name</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="thanaId" id="thanaId" >
                    <option value="">Select thana</option>
                    <ng-container *ngFor="let item of ThanaList">
                      <option [value]="item.id">{{item.upazilaName}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>

             <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="lastTaxReturnYear" class="col-sm-4 col-form-label">Last Tax Return Year</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="lastTaxReturnYear"
                    placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="nameOfEnterprise" class="col-sm-4 col-form-label">Name of Enterprise</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="nameOfEnterprise"
                    placeholder="Type here...">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="establishDate" class="col-sm-4 col-form-label">Establish Date of Enterprise</label>
                <div class="col-sm-8">
                  <input type="date" class="form-control" formControlName="establishDate" placeholder="Type here....">
                </div>
              </div>
            </div>
             <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="businessSales" class="col-sm-4 col-form-label">Sales of Business</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="businessSales" placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="concernStock" class="col-sm-4 col-form-label">Stock of Concern</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="concernStock" placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="enterpriseAge" class="col-sm-4 col-form-label">Age of Enterprise</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="enterpriseAge" placeholder="0">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="legalEntity" class="col-sm-4 col-form-label">Legal Entity</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="legalEntity" placeholder="Type here....">
                </div>
              </div>
            </div>
          <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="enterpriseNature" class="col-sm-4 col-form-label">Nature of Enterprise</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="enterpriseNature"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="businessLocation" class="col-sm-4 col-form-label">Business Location</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="businessLocation"
                    placeholder="Type here....">
                </div>
              </div>
            </div>

             <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="tradeLicenceNo" class="col-sm-4 col-form-label">Trade Licence Register No</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="tradeLicenceNo" placeholder="Type here...">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType==='Business Guarantor'">
              <div class="form-group row">
                <label for="segmentEnterprise" class="col-sm-4 col-form-label">Segment of Enterprise</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="segmentEnterprise"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType ==='Business Guarantor'">
              <div class="form-group row">
                <label for="subSectorEnterprise" class="col-sm-4 col-form-label">Sub Sector of Enterprise</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="subSectorEnterprise"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType ==='Business Guarantor'">
              <div class="form-group row">
                <label for="clusterName" class="col-sm-4 col-form-label">Name of Cluster</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="clusterName" placeholder="Type here....">
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="guarantorType ==='Business Guarantor'">
              <div class="form-group row">
                <label for="initialEquityAbsoluteFigure" class="col-sm-4 col-form-label">Initial Equity Absolute
                  Figure</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="initialEquityAbsoluteFigure"
                  (input)="frmLoanGurantor.get('initialEquityAbsoluteFigure').setValue(formatNumber($event.target.value))"
                    placeholder="0" style="text-align: right;">
                    <span *ngIf="frmLoanGurantor.get('initialEquityAbsoluteFigure').hasError('pattern')" class="text-danger"> Only numeric values are allowed. </span>
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType ==='Business Guarantor'">
              <div class="form-group row">
                <label for="presentEquityAbsoluteFigure" class="col-sm-4 col-form-label">Present Equity Absolute
                  Figure</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="presentEquityAbsoluteFigure"
                  (input)="frmLoanGurantor.get('presentEquityAbsoluteFigure').setValue(formatNumber($event.target.value))"
                  placeholder="0" style="text-align: right;">
                  <span *ngIf="frmLoanGurantor.get('presentEquityAbsoluteFigure').hasError('pattern')" class="text-danger"> Only numeric values are allowed. </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="monthlyExpense" class="col-sm-4 col-form-label">Monthly Expense</label>
                <div class="col-sm-8">
                  <input type="text" style="text-align: right;" class="form-control" formControlName="monthlyExpense"
                  (input)="frmLoanGurantor.get('monthlyExpense').setValue(formatNumber($event.target.value))" placeholder="0">
                  <span *ngIf="frmLoanGurantor.get('monthlyExpense').hasError('pattern')" class="text-danger"> Only numeric values are allowed. </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="relationshipWithEBL" class="col-sm-4 col-form-label">Relationship With EBL</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="relationshipWithEBL"
                    placeholder="Type here....">
                </div>
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group row">
                <label for="personalWorth" class="col-sm-4 col-form-label">Personal Worth</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="personalWorth" style="text-align: right;"
                  (input)="frmLoanGurantor.get('personalWorth').setValue(formatNumber($event.target.value))" placeholder="0">
                  <span *ngIf="frmLoanGurantor.get('personalWorth').hasError('pattern')" class="text-danger"> Only numeric values are allowed. </span>
                </div>
              </div>
            </div>
           <div class="col-md-6" *ngIf="guarantorType==='Family Guarantor'">
              <div class="form-group row">
                <label for="eduQualification" class="col-sm-4 col-form-label">Educational Qualification</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="eduQualification"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="guarantorType==='Family Guarantor'">
              <div class="form-group row">
                <label for="organizationName" class="col-sm-4 col-form-label">Organization Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="organizationName"
                    placeholder="Type here....">
                </div>
              </div>
            </div>
             <div class="col-md-6" *ngIf="guarantorType==='Family Guarantor'">
              <div class="form-group row">
                <label for="organizationAddress" class="col-sm-4 col-form-label">Organization Address</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="organizationAddress"
                    placeholder="Type here....">
                </div>
              </div>
            </div>


            <div class="col-md-6" *ngIf="guarantorType==='Family Guarantor'">
              <div class="form-group row">
                <label for="guaranteeAmount" class="col-sm-4 col-form-label">Guarantee Amount</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="guaranteeAmount" style="text-align: right;"
                  (input)="frmLoanGurantor.get('guaranteeAmount').setValue(formatNumber($event.target.value))"
                    placeholder="0">
                    <span *ngIf="frmLoanGurantor.get('guaranteeAmount').hasError('pattern')" class="text-danger"> Only numeric values are allowed. </span>
                </div>
              </div>
            </div>

          </div>
          <button (click)="saveLoanGurantor()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
            <span>Save</span></button>  
          <!-- <button title="Save" type="submit" value="Submit" (click)="saveLoanGurantor()" class="btn btn-success"
            style="float:right; margin-top:5px;"> -->
            <!-- <span>{{ isEdit ? 'Update' : 'Save' }}</span></button> -->
            <!-- <span>Save</span></button> -->
          <button type="button" title="Refresh" class="btn btn-secondary" (click)="resetLoanGurantorFrm()"
            style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>

        </form>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="container">
      <h5 class="card-title" style="color: black">Guarantor List</h5>
     
      <table id="example" class="table table-bordered">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Guarantor Code</th>
            <th>Relation with Customer </th>
            <th>Mobile</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of GuarantorList; let i = index">
            <td>{{i+1}}</td>
            <td>{{item.name}}</td>
            <td>{{item.relationwithApplicant }}</td>
            <td>
              {{item.mobile }}
            </td>
            <td>
              <button class="btn-sm btn-primary" (click)="editGuarantor(item)"><i class="fa fa-edit"></i></button>
              <button [disabled]="isEditBank" class="btn-sm btn-danger" style="margin-left: 5px;"
                (click)="DeleteGuarantor(item.id)"><i class="fa fa-trash-o"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
     
    </div>
  </div>