
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoanApplicationRoutingModule } from './loan-application-routing.module';
import { LeadGenerationComponent } from './lead-generation/lead-generation.component';
import { LoanApplicationComponent } from './loan-application/loan-application.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BasicInfoSelfComponent } from './basic-info-self/basic-info-self.component';
import { HttpClientModule } from '@angular/common/http';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { AddressInfoComponent } from './address-info/address-info.component';
import { ProfessionalInfoComponent } from './professional-info/professional-info.component';
import { IncomeDetailsComponent } from './income-details/income-details.component';
import { ExpenseDetailsComponent } from './expense-details/expense-details.component';
import { BankaccountDetailsComponent } from './bankaccount-details/bankaccount-details.component';
import { ReferenceDetailsComponent } from './reference-details/reference-details.component';
import { LoanRegisterComponent } from './loan-register/loan-register.component';
import { HomeLoanComponent } from './home-loan/home-loan.component';
import { VehicleLoanComponent } from './vehicle-loan/vehicle-loan.component';
import { GurantorInfoComponent } from './gurantor-info/gurantor-info.component';
import { GurantorBusinessStandingComponent } from './gurantor-business-standing/gurantor-business-standing.component';
import { CreditWorthinessComponent } from './credit-worthiness/credit-worthiness.component';
import { GurantorNetworthComponent } from './gurantor-networth/gurantor-networth.component';
import { SecurityTypeComponent } from './security-type/security-type.component';
import { LoanDocumentComponent } from './loan-document/loan-document.component';
import { CustomerLoanCreateComponent } from './customer-loan-create/customer-loan-create.component';
import { DisburseLeadInfoComponent } from './disburse-lead-info/disburse-lead-info.component';
import { DisburseCollectionComponent } from './disburse-collection/disburse-collection.component';
import { DynamicLoanApplicationComponent } from './dynamic-loan-application/dynamic-loan-application.component';
import { CustomerBasicInfoForEditComponent } from './customer-basic-info-for-edit/customer-basic-info-for-edit.component';
import { EKycModule } from 'app/e-kyc/e-kyc.module';

import { LoanApplicationFullViewComponent } from './loan-application-full-view/loan-application-full-view.component';
import { LoanListComponent } from './loan-list/loan-list.component';
import { PartnersComponent } from './BNPL/partners/partners.component';
import { BnplPartnerComponent } from './BNPL/bnpl-partner/bnpl-partner.component';


@NgModule({
  declarations: [
    LeadGenerationComponent,
    LoanApplicationComponent,
    CustomerBasicInfoForEditComponent,
    BasicInfoSelfComponent,
    ContactInfoComponent,
    AddressInfoComponent,
    ProfessionalInfoComponent,
    IncomeDetailsComponent,
    ExpenseDetailsComponent,
    BankaccountDetailsComponent,
    ReferenceDetailsComponent,
    LoanRegisterComponent,
    HomeLoanComponent,
    VehicleLoanComponent,
    GurantorInfoComponent,
    GurantorBusinessStandingComponent,
    CreditWorthinessComponent,
    GurantorNetworthComponent,
    SecurityTypeComponent,
    LoanDocumentComponent,
    CustomerLoanCreateComponent,
    DisburseLeadInfoComponent,
    DisburseCollectionComponent,
    DynamicLoanApplicationComponent,
    CustomerBasicInfoForEditComponent,
    LoanApplicationFullViewComponent,
    LoanListComponent,
    PartnersComponent,
    BnplPartnerComponent,
  ],
  imports: [
    //CommonModule,
    //HttpClientModule,
    //FormsModule,
    //ReactiveFormsModule,
    FormsModule, 
    ReactiveFormsModule,
    CommonModule,
    NgbModule ,
    HttpClientModule,
    LoanApplicationRoutingModule,
    EKycModule
  ],
  exports: [
   //BasicInfoSelfComponent, 
   CustomerBasicInfoForEditComponent,
   AddressInfoComponent,
   ContactInfoComponent,
   ProfessionalInfoComponent,
   IncomeDetailsComponent,
   ExpenseDetailsComponent,
   BankaccountDetailsComponent,
   ReferenceDetailsComponent,

  ],
  providers: [],
  bootstrap: [LoanRegisterComponent],
})
export class LoanApplicationModule { }
